"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseSections = void 0;
function parseSections(sections) {
    var parsedSections = {};
    sections.forEach(function (section) {
        if (!parsedSections[section.sectionId]) {
            parsedSections[section.sectionId] = [];
        }
        parsedSections[section.sectionId].push(section);
    });
    return parsedSections;
}
exports.parseSections = parseSections;
