"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.excludePhTypes = void 0;
var entityTypes_1 = require("../../../utils/entityTypes");
var constants_1 = require("../constants");
function excludePhTypes(placeholderTypes, aggregation, templateType) {
    var _a;
    // покажем только те опции которые не скрыты canShow
    var filteredPhTypes = (_a = placeholderTypes === null || placeholderTypes === void 0 ? void 0 : placeholderTypes.filter(function (phType) { return phType.canShow === true; })) !== null && _a !== void 0 ? _a : [];
    if ((0, entityTypes_1.isEntityTypeVariant)(templateType) || (0, entityTypes_1.isCourtDocumentEntity)(templateType)) {
        filteredPhTypes = filteredPhTypes.filter(function (t) { return t.id !== 'fromTemplate'; });
    }
    if (aggregation.type !== 'attachment') {
        filteredPhTypes = filteredPhTypes.filter(function (t) { return t.id !== 'attachment'; });
    }
    if (!aggregation.subType || aggregation.subType !== 'boundary') {
        filteredPhTypes = filteredPhTypes.filter(function (t) { return t.id !== 'exportContent'; });
    }
    if (aggregation.subType === 'repeated') {
        filteredPhTypes = filteredPhTypes.filter(function (t) { return constants_1.REPEATABLE_PH_TYPES.includes(t.id); });
    }
    return filteredPhTypes;
}
exports.excludePhTypes = excludePhTypes;
