"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnfilledPlaceHolders = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var UnfilledPlaceHolder_1 = require("./UnfilledPlaceHolder");
var UnfilledPlaceHolders = function (_a) {
    var unfilledPlaceholders = _a.unfilledPlaceholders, _b = _a.placeholdersValue, placeholdersValue = _b === void 0 ? [] : _b, _c = _a.templatedListBlocks, templatedListBlocks = _c === void 0 ? [] : _c, _d = _a.sections, sections = _d === void 0 ? [] : _d;
    // выделяем значения ПХ которые относятся конкретно к Списковым, будем работать именно с ними
    var filteredPlaceholdersValue = placeholdersValue === null || placeholdersValue === void 0 ? void 0 : placeholdersValue.filter(function (phValue) { var _a; return (_a = phValue.extendedAttributes) === null || _a === void 0 ? void 0 : _a.TemplatedListPlaceholderId; });
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: unfilledPlaceholders.map(function (ph, index) { return ((0, jsx_runtime_1.jsx)(UnfilledPlaceHolder_1.UnfilledPlaceHolder, { placeholder: ph, ind: index, filteredPlaceholdersValue: filteredPlaceholdersValue, templatedListBlocks: templatedListBlocks, sections: sections }, ph.id)); }) }));
};
exports.UnfilledPlaceHolders = UnfilledPlaceHolders;
