"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.entityTypeWithTemplatedList = exports.isCourtDocumentEntity = exports.isEntityTypeVariant = exports.isAttachmentType = exports.isTemplatedList = exports.isLibrary = exports.isAttachmentFooter = exports.isAttachmentHeader = exports.ENTITY_TYPES = void 0;
exports.ENTITY_TYPES = {
    //
    // ОБЩИЕ ДЛЯ ВСЕХ РОЛЕЙ
    //
    /**
     * Библиотечные блоки
     */
    library: 'library',
    /**
     * Приложение
     */
    attachment: 'attachment',
    /**
     * Списки
     */
    templatedList: 'templatedList',
    //
    // ШАБЛОНЫ ДОГОВОРНЫХ ДОКУМЕНТОВ
    //
    /**
     * Договора
     */
    contract: 'contract',
    /**
     * Дополнительные соглашения
     */
    additionalAgreement: 'additionalAgreement',
    /**
     * Трехсторонние договоры
     */
    cession: 'cession',
    /**
     * Соглашение о расторжении
     */
    terminationAgreement: 'terminationAgreement',
    //
    // ШАБЛОНЫ ДОВЕРЕННОСТЕЙ
    //
    /**
     * Доверенности
     */
    powerOfAttorney: 'powerOfAttorney',
    /**
     * Уведомления об отзыве доверенностей
     */
    revokePowerOfAttorney: 'revokePowerOfAttorney',
    /**
     * Полномочия для доверенностей
     */
    authority: 'authority',
    //
    // ШАБЛОНЫ СУДЕБНЫХ ДОКУМЕНТОВ
    //
    /**
     * Исковые заявления
     */
    statementOfClaim: 'statementOfClaim',
    /**
     * Заявления
     */
    statements: 'statements',
    /**
     * Отзывы на исковые заявления
     */
    revocationStatementOfClaim: 'revocationStatementOfClaim',
    /**
     * Ходатайства
     */
    petitions: 'petitions',
    //
    // СЛУЖЕБНЫЕ СУЩНОСТИ
    //
    document: 'document',
    // [TODO] - Удалить
    folder: 'folder',
    subpart: 'subpart',
    attachmentHeader: 'attachmentHeader',
    attachmentFooter: 'attachmentFooter',
};
var ENTITY_TYPE_VARIANT = [exports.ENTITY_TYPES.powerOfAttorney, exports.ENTITY_TYPES.authority, exports.ENTITY_TYPES.revokePowerOfAttorney];
var ENTITY_TYPE_DOCUMENT = [
    exports.ENTITY_TYPES.statementOfClaim,
    exports.ENTITY_TYPES.statements,
    exports.ENTITY_TYPES.petitions,
    exports.ENTITY_TYPES.revocationStatementOfClaim,
];
var isAttachmentHeader = function (entityType) {
    if (entityType === void 0) { entityType = ''; }
    return entityType === exports.ENTITY_TYPES.attachmentHeader;
};
exports.isAttachmentHeader = isAttachmentHeader;
var isAttachmentFooter = function (entityType) {
    if (entityType === void 0) { entityType = ''; }
    return entityType === exports.ENTITY_TYPES.attachmentFooter;
};
exports.isAttachmentFooter = isAttachmentFooter;
var isLibrary = function (entityType) {
    if (entityType === void 0) { entityType = ''; }
    return entityType === exports.ENTITY_TYPES.library;
};
exports.isLibrary = isLibrary;
var isTemplatedList = function (entityType) {
    if (entityType === void 0) { entityType = ''; }
    return entityType === exports.ENTITY_TYPES.templatedList;
};
exports.isTemplatedList = isTemplatedList;
var isAttachmentType = function (entityType) {
    if (entityType === void 0) { entityType = ''; }
    return (0, exports.isAttachmentHeader)(entityType) && (0, exports.isAttachmentFooter)(entityType);
};
exports.isAttachmentType = isAttachmentType;
var isEntityTypeVariant = function (entityType) {
    if (entityType === void 0) { entityType = ''; }
    return ENTITY_TYPE_VARIANT.includes(entityType);
};
exports.isEntityTypeVariant = isEntityTypeVariant;
var isCourtDocumentEntity = function (entityType) {
    if (entityType === void 0) { entityType = ''; }
    return ENTITY_TYPE_DOCUMENT.includes(entityType);
};
exports.isCourtDocumentEntity = isCourtDocumentEntity;
var entityTypeWithTemplatedList = function (entityType) {
    return entityType === 'powerOfAttorney' ||
        entityType === 'revokePowerOfAttorney' ||
        entityType === 'statements' ||
        entityType === 'statementOfClaim' ||
        entityType === 'revocationStatementOfClaim' ||
        entityType === 'petitions';
};
exports.entityTypeWithTemplatedList = entityTypeWithTemplatedList;
exports.default = exports.ENTITY_TYPES;
