"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnfilledTemplatedListPlaceHolderContent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var placeholderUtils_1 = require("../../../../shared/utils/placeholderUtils");
var UnfilledTemplatedListPlaceHolderContent = function (_a) {
    var placeholder = _a.placeholder, currentPlaceholderOrder = _a.currentPlaceholderOrder, filteredPlaceholdersValue = _a.filteredPlaceholdersValue;
    var desc = placeholder.desc;
    var emptyPlaceholdersAcc = (0, placeholderUtils_1.findUnfilledTemplatedListPlaceholderParameters)(placeholder, filteredPlaceholdersValue);
    var renderUnfilledListPhs = emptyPlaceholdersAcc === null || emptyPlaceholdersAcc === void 0 ? void 0 : emptyPlaceholdersAcc.map(function (item) { return item === null || item === void 0 ? void 0 : item.templatedListName; }).join(', ');
    return (0, jsx_runtime_1.jsx)("div", { children: "".concat(currentPlaceholderOrder, " ").concat(desc, ": ").concat(renderUnfilledListPhs) });
};
exports.UnfilledTemplatedListPlaceHolderContent = UnfilledTemplatedListPlaceHolderContent;
