"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttrUtils = void 0;
var constants_1 = require("../../constants");
var TypeUtils_1 = require("./TypeUtils");
var uuid_1 = require("uuid");
var AttrUtils = /** @class */ (function () {
    function AttrUtils() {
    }
    // set
    AttrUtils.setPlaceholderIdAttrs = function (element, id) {
        if (id === void 0) { id = (0, uuid_1.v4)(); }
        element.setAttribute('id', "ph_".concat(id));
        element.setAttribute('data-placeholder', id);
        return id;
    };
    AttrUtils.fixPlaceholderAttrs = function (element) {
        element.setAttribute('onclick', 'window.textEditor.onPlaceholderClick(this)');
    };
    AttrUtils.generateId = function (value, prefix, postfix) {
        if (postfix === void 0) { postfix = null; }
        return [prefix, value, postfix].filter(function (v) { return v !== null; }).join('_');
    };
    AttrUtils.generateKey = function (value, postfix) {
        return "".concat(value, "_").concat(postfix);
    };
    AttrUtils.getElementId = function (node) {
        var _a;
        return TypeUtils_1.TypeUtils.isElement(node) ? (_a = AttrUtils.parseId(node.id)) !== null && _a !== void 0 ? _a : '' : '';
    };
    AttrUtils.getElementAttr = function (node, attr) {
        var _a;
        if (attr === void 0) { attr = 'id'; }
        return TypeUtils_1.TypeUtils.isElement(node) ? (_a = node.getAttribute(attr)) !== null && _a !== void 0 ? _a : '' : '';
    };
    AttrUtils.getBoundaryId = function (node) {
        return AttrUtils.getElementAttr(node, constants_1.BOUNDARY_ATTRS.id);
    };
    AttrUtils.parseId = function (value) {
        if (typeof value !== 'string') {
            return '';
        }
        var parts = value.split('_');
        return parts.length > 1 ? parts[parts.length - 1] : parts[0];
    };
    AttrUtils.createPhId = function (id) {
        return AttrUtils.generateId(id, 'ph');
    };
    AttrUtils.createWrapperId = function (id) {
        return AttrUtils.generateId(id, 'wrapper');
    };
    AttrUtils.createPhWrapAttachmentId = function (id) {
        return AttrUtils.generateId(id, 'ph_wrap');
    };
    AttrUtils.createBoundaryRightPhId = function (id) {
        return AttrUtils.generateId(id, 'ph', 'right');
    };
    return AttrUtils;
}());
exports.AttrUtils = AttrUtils;
