import { createGlobalStyle} from "styled-components";

export const GlobalStyles = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.body};
  }
  .main-wrapper {
    background-color: ${({ theme }) => theme.body};
  }
  .content-wrapper {
    background-color: ${({ theme }) => theme.body};
  }
  .header-wrapper-home {
    background-color: ${({ theme }) => theme.body};
  }
  .card-segment {
    background-color: ${({ theme }) => theme.body};
    box-shadow: ${({ theme }) => theme.shadowStandard};
  }
  .folder-header {
    background-color: ${({ theme }) => theme.body};
  }
  .sidebar-wrapper {
    box-shadow: ${({ theme }) => theme.shadowStandard};
    background-color: ${({ theme }) => theme.sidebarBg};
  }
  .wrapper {
    box-shadow: ${({ theme }) => theme.shadowBottom};
  }
  .ant-btn {
    &-primary, .header-button {
      background-color: ${({ theme }) => theme.baseColor};
      border-color: ${({ theme }) => theme.baseColor};
      &:hover {
        background-color: ${({ theme }) => theme.hoverColor};
        border-color: ${({ theme }) => theme.hoverColor};
      }
      &:focus {
        background-color: ${({ theme }) => theme.focusColor};
        border-color: ${({ theme }) => theme.focusColor};
      }
    }
  }

  .ant-card {
    background-color: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
  }

  .ant-card.new-message {
    outline: 1px solid ${({ theme }) => theme.baseColor};
  }

  .ant-card-head {
    background-color: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.baseColor};
  }

  .toggle-menu-button {
    background-color: ${({ theme }) => theme.lightBtn};
    border-color: ${({ theme }) => theme.lightBtn};
    color: ${({ theme }) => theme.toggleBtn.color};
    &:hover {
      background-color: ${({ theme }) => theme.lightBtnHover};
      border-color: ${({ theme }) => theme.lightBtnHover};
      color: ${({ theme }) => theme.toggleBtn.color};
    }
    &:focus {
      background-color: ${({ theme }) => theme.lightBtnFocus};
      border-color: ${({ theme }) => theme.lightBtnFocus};
      color: ${({ theme }) => theme.toggleBtn.color};
    }
  }

  .ant-dropdown-menu-title-content {
    &:hover {
      color: ${({ theme }) => theme.baseColor};
    }
  }
  .menu-buttons {
    &__create {
      background-color: ${({ theme }) => theme.baseColor};
      color: ${({ theme }) => theme.btnColorDefault};
      &:hover {
        background-color: ${({ theme }) => theme.hoverColor};
        border-color: ${({ theme }) => theme.hoverColor};
      }
      &:focus {
        background-color: ${({ theme }) => theme.focusColor};
        border-color: ${({ theme }) => theme.focusColor};
      }
    }
  }
  .breadcrumbs {
    color: #6a767e;
    & a:hover {
      color: #384650;
    }
    & .current-breadcrumb {
      color: #384650;
    }
    &.ant-breadcrumb {
      .current-breadcrumb {
        color: ${({ theme }) => theme.baseColor};
      }
    }
  }
  .item {
    &-version {
      color: ${({ theme }) => theme.transitiveСolor};
    }
  }
  .ant-checkbox-wrapper {
    color: ${({ theme }) => theme.text};
  }
  .ant-checkbox-checked:not(.ant-checkbox-disabled) {
    .ant-checkbox-inner {
      background-color: ${({ theme }) => theme.baseColor};
      border-color: ${({ theme }) => theme.baseColor};
    }
  }
  .explorer-menu {
    .menu-buttons {
      &__show-archive {
        .ant-checkbox {
          .ant-checkbox-inner {
            border: 1px solid ${({ theme }) => theme.checkboxBorderColor};
          }
          &.ant-checkbox-checked {
            .ant-checkbox-inner {
              background-color: ${({ theme }) => theme.baseColor};
            }
          }
        }
      }
    }
  }
  .explorer-menu {
    .header-search {
      input {
        color: ${({ theme }) => theme.text};
        &:focus {
          box-shadow: ${({ theme }) => `0 2px 0px 0px ${theme.baseColor}`};
        }
      }
    }
  }
  .breadcrumbs {
    & .breadcrumb-link {
      color: ${({ theme }) => theme.text};
      &:hover {
        color: ${({ theme }) => theme.textDark};
      }
    }
  }
  .ant-pagination.ant-pagination-mini .ant-pagination-item.ant-pagination-item-active {
    border-color: ${({ theme }) => theme.baseColor};
    background-color: ${({ theme }) => theme.baseColor};
    a {
      color: #FFFFFF;
    }
  }
  .ant-pagination-item a {
    color: ${({ theme }) => theme.textDark};
  }
  .ant-pagination-prev {
    button {
      color: ${({ theme }) => theme.textDark};
    }
  }
  .ant-pagination-next {
    button {
      color: ${({ theme }) => theme.textDark};
    }
  }
  .ant-menu, .ant-menu-submenu-popup > .ant-menu {
    background-color: ${({ theme }) => theme.sidebarBg};
    box-shadow: ${({ theme }) => theme.shadowStandard};
  }

  .ant-menu-sub.ant-menu-inline {
    background-color: ${({ theme }) => theme.sidebarBg};
  }

  .ant-table {
    background-color: ${({ theme }) => theme.body};
  }
  .ant-menu-title-content {
    color: ${({ theme }) => theme.menuText};
    &:hover {
      color: ${({ theme }) => theme.menuText};
    }
  }
  .explorer-menu {
    .menu-buttons {
      &__show-archive {
        .ant-checkbox-wrapper {
          color: ${({ theme }) => theme.textDark};
        }
      }
    }
  }
  .ant-menu-submenu-expand-icon,
  .ant-menu-submenu-arrow {
    color: ${({ theme }) => theme.mainFolderColor};
  }
  .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
      color: ${({ theme }) => theme.mainFolderColor};
  }
  .ant-table-container table > thead {
    th {
      border-top: ${({ theme }) => `2px solid ${theme.baseColor}`};
      border-bottom: ${({ theme }) => `2px solid ${theme.baseColor}`};
      color: ${({ theme }) => theme.text};
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${({theme}) => theme.body};
    color: ${({theme}) => theme.textDark};
  }
  .ant-select-arrow {
    color: ${({theme}) => theme.textDark};
  }
  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link {
    color: ${({theme}) => theme.textDark};
    opacity: 0.5;
  }
  .ant-table,
  .item-row-text {
    color: ${({theme}) => theme.textDark};
    &__label {
      color: ${({theme}) => theme.text};
    }
  }
  .ant-checkbox-inner {
    border: 1px solid ${({ theme }) => theme.checkboxBorderColor};
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    border-bottom-color: ${({theme}) => theme.toggleBorder};
    transition: border 0.5s linear;
  }
  .bulk-menu {
    background-color: ${({theme}) => theme.body};
    border: 1px solid ${({theme}) => theme.toggleBorder};
    color: ${({theme}) => theme.textDark};
    .bulk-btn {
      color: ${({theme}) => theme.baseColor};
      &:hover {
        color: ${({ theme }) => theme.hoverColor};
      }
      &:focus {
        color: ${({ theme }) => theme.focusColor};
      }
    }
    &__close {
      svg {
        fill: ${({theme}) => theme.textDark};
      }
    }
  }
  .ant-menu-inline.ant-menu-root {
    color: ${({theme}) => theme.mainFolderColor};
    background-color: ${({theme}) => theme.sidebarBg};
    & > .ant-menu-submenu {
      & > .ant-menu-submenu-title {
        color: ${({theme}) => theme.mainFolderColor};
        & > .ant-menu-title-content {
          color: ${({theme}) => theme.mainFolderColor};
        }
      }
    }
  }
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    color: ${({theme}) => theme.mainFolderColor};
  }
  .ant-empty-description {
    color: ${({theme}) => theme.textDark};
  }
  .ant-tree {
    background-color: ${({theme}) => theme.body};
    .ant-tree-node-content-wrapper {
      &:hover {
        background-color: ${({theme}) => theme.activeBgColor};
      }
    }
  }
  .folder-tree {
    &__node {
      .ant-tree-title {
        color: ${({theme}) => theme.textDark};
      }
    }
  }
  .template-search-input {
    color: ${({theme}) => theme.textDark};
  }
  .document-button:not([disabled]) {
      background-color: ${({ theme }) => theme.baseColor};
      color: ${({ theme }) => theme.btnColorDefault};
      border-color: ${({theme}) => theme.baseColor};
      &:hover {
        background-color: ${({ theme }) => theme.hoverColor};
        border-color: ${({ theme }) => theme.hoverColor};
        color: ${({ theme }) => theme.btnColorDefault};
      }
      &:focus {
        background-color: ${({ theme }) => theme.focusColor};
        border-color: ${({ theme }) => theme.focusColor};
        color: ${({ theme }) => theme.btnColorDefault};
      }
  }
  .document-button, .ant-btn {
     &[disabled] {
      background-color: ${({theme}) => theme.disabled};
      border-color: ${({theme}) => theme.disabled};
      color: ${({theme}) => theme.disabledText};
      &:hover {
        background-color: ${({theme}) => theme.disabled};
        color: ${({theme}) => theme.disabledText};
        border-color: ${({theme}) => theme.disabled};
      }
    }
  }
  .editor {
    background-color: ${({ theme }) => theme.docBackground};
  }
  .document-body {
    border: ${({ theme }) => theme.docBorder};
    background-color: ${({theme}) => theme.body};
    box-shadow: ${({ theme }) => theme.shadowStandard};
    color: ${({ theme }) => theme.textDark};
  }
  .document-controls {
    &__inner {
      border: ${({ theme }) => theme.docBorder};
      background-color: ${({theme}) => theme.body};
      box-shadow: ${({ theme }) => theme.shadowStandard};
      color: ${({theme}) => theme.textDark};
      & > * {
        color: ${({theme}) => theme.textDark};
      }
    }
  }
  .form-panel {
    .body {
      background-color: ${({theme}) => theme.body};
      & > * {
        color: ${({theme}) => theme.textDark};
      }
    }
    .title {
      background-color: ${({theme}) => theme.body};
      & > * {
        color: ${({theme}) => theme.textDark};
      }
    }
  }
  .document-render {
    & > * {
      color: ${({theme}) => theme.textDark};
    }
    .block {
      background-color: ${({theme}) => theme.body} !important;
      &--table {
        td, th {
          border: ${({ theme }) => `1px solid ${theme.textDark}`} !important;
        }
      }
    }
  }
  .container {
    background-color: ${({theme}) => theme.body};
  }
  .icon__back, .icon__close {
    svg {
      fill: ${({theme}) => theme.text};
    }
  }
  .ant-input {
    background-color: ${({theme}) => theme.body};
    color: ${({theme}) => theme.textDark};
    &[disabled] {
      background-color: ${({ theme }) => theme.disabled};
      color: ${({ theme }) => theme.disabledText};
    }
  }
  .block--attachment .ant-btn[disabled] {
    background-color: ${({ theme }) => theme.disabled};
    color: ${({ theme }) => theme.disabledText};
  }
  .info .info__main .name {
    color: ${({theme}) => theme.textDark};
  }
  .document-header {
    background-color: ${({ theme }) => theme.body};
    box-shadow: none;
  }
  .document-header.view {
    box-shadow: ${({ theme }) => theme.shadowStandard};
  }
  .block span.placeholder {
    background-color: ${({ theme }) => theme.baseColor};
  }
  .menu-container {
    background-color: transparent;
    &-inner-wrapper {
      background-color: ${({ theme }) => theme.body};
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
    }
  }
  .panel {
    & .systems-catalogue, .cascader-options .external-params-switch {
      background-color: ${({ theme }) => theme.disabled};
      & > * {
        color: ${({ theme }) => theme.disabledText};
      }
    }
    &__textarea {
      background-color: ${({ theme }) => theme.body};
    }
  }
  .relation-options, .option {
    background-color: ${({ theme }) => theme.disabled};
    & > * {
      color: ${({ theme }) => theme.disabledText};
    }
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: ${({ theme }) => theme.disabled};
    color: ${({ theme }) => theme.disabledText};
  }
  .ant-modal-content {
    box-shadow: ${({ theme }) => theme.shadowStandard};
    background-color: ${({ theme }) => theme.body};
    color: ${({theme}) => theme.textDark};
    & > * {
      color: ${({theme}) => theme.textDark};
    }
  }
  .ant-modal-confirm-body .ant-modal-confirm-title {
    color: ${({theme}) => theme.textDark};
  }
  .ant-modal-confirm-body .ant-modal-confirm-content {
    color: ${({theme}) => theme.textDark};
  }
  .ant-modal-close {
    color: ${({theme}) => theme.textDark};
    &:hover {
      color: ${({theme}) => theme.baseColor};
    }
  }
  .ant-modal-header {
    background-color: ${({ theme }) => theme.body};
    color: ${({theme}) => theme.textDark};
    & > * {
      color: ${({theme}) => theme.textDark};
    }
  }
  .ant-legacy-form-item-label {
    & > label {
      color: ${({theme}) => theme.textDark};
    }
  }
  .more__options {
    .ant-dropdown-menu {
      box-shadow: ${({theme}) => `0 3px 6px -4px ${theme.shadowColors.colorLight}, 0 6px 16px 0 ${theme.shadowColors.colorMiddle}, 0 9px 28px 8px ${theme.shadowColors.colorDark}`};
    }
  }
  .item-title-name {
    &:hover {
      color: ${({theme}) => theme.hoverColor};
    }
  }
  .template-card-segment .template-search-bar .template-search-input:focus {
    box-shadow: ${({ theme }) => `0 2px 0px 0px ${theme.baseColor}`};
  }
  .editor {
    & .header-button {
      &.light {
        background: transparent;
        border: ${({ theme }) => `1px solid ${theme.baseColor}`};
        color: ${({ theme }) => theme.baseColor};

        &:active {
          outline: none;
          background-color: ${({ theme }) => theme.focusColor};
        }
      }
    }
  }
  .form-panel .body .form-panel-form label span {
    color: ${({ theme }) => theme.textDark};
  }
  .document-render .block span.placeholder.editing {
    background-color: ${({ theme }) => theme.focusColor};
  }
  td.ant-table-column-sort,
  th.ant-table-column-sort,
  .ant-table-thead th.ant-table-column-sort {
    background: transparent;
  }
  .file-uploader {
    &__acceptable {
      &-types {
        color: ${({ theme }) => theme.textDark};
      }
    }
  }
  .file-uploader {
    .uploaded-file {
      &:hover {
        background-color: transparent;
      }
    }
  }
  .section-wrapper {
    &--active, &.selected, &.library-section {
      background: ${({ theme }) => theme.activeBgColor};
    }
  }
  .library-container, .library-name {
    background: ${({ theme }) => theme.activeBgColor};
  }
  .group, .block.library-block,
  .option_header,
  .section-wrapper.library-section .group {
    background-color: ${({theme}) => theme.body};
    color: ${({theme}) => theme.textDark};
  }
  .block-wrapper.selected {
    background: ${({ theme }) => theme.activeBgColor};
  }
  .block--attachment .ant-btn {
    color: ${({ theme }) => theme.textDark};
  }
  .ant-form-item {
    color: ${({ theme }) => theme.textDark};
  }
  .ant-menu-vertical.ant-menu-sub {
    width: 100%;
    max-width: 240px;
    color: ${({theme}) => theme.mainFolderColor};
  }
  .ant-menu-vertical.ant-menu-sub > .ant-menu-submenu {
    color: ${({theme}) => theme.mainFolderColor};
    .ant-menu-submenu-title {
      color: ${({theme}) => theme.mainFolderColor};
      .ant-menu-title-content {
        flex: auto;
        min-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        color: ${({theme}) => theme.mainFolderColor};
      }
    }
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: ${({theme}) => theme.baseColor};
    &::after {
      background-color: ${({theme}) => theme.baseColor};
    }
  }
  .loader {
    &__icon {
      color: ${({ theme }) => theme.textDark};
    }
    &__text {
      color: ${({ theme }) => theme.textDark};
    }
  }
  .panel .attachment-template__info {
    .name, .version {
      background-color: ${({ theme }) => theme.activeBgColor};
    }
  }
  .unlock-button {
    filter: ${({ theme }) => theme.filter};
  }
  .unlock-title {
      background: ${({ theme }) => theme.tooltip.background};
      color: ${({ theme }) => theme.tooltip.color};
  }
  .block-wrapper:not(.section-wrapper) {
    &.library-section {
      background: ${({ theme }) => theme.activeBgColor};
      .library-block {
        background: ${({ theme }) => theme.activeBgColor};
      }
    }
  }
  .insert-library-container {
    background-color: ${({ theme }) => theme.body};
    & > * {
      color: ${({ theme }) => theme.textDark};
    }
  }
  .button_editor-menu {
    svg {
      fill: ${({ theme }) => theme.svgThemes.invert} !important;
      path, use, g {
        fill: ${({ theme }) => theme.svgThemes.invert} !important;
      }
    }
    .button__text {
      color: ${({ theme }) => theme.svgThemes.invert} !important;
    }
    &.button_selected {
      svg {
        fill: ${({ theme }) => theme.svgThemes.selected} !important;
        path, use, g {
          fill: ${({ theme }) => theme.svgThemes.selected} !important;
        }
      }
    }
    &.button_hovered {
      color: ${({ theme }) => theme.svgThemes.fill};
      background: ${({ theme }) => theme.svgThemes.invert};
      svg {
        fill: ${({ theme }) => theme.svgThemes.fill} !important;
        path, use, g {
          fill: ${({ theme }) => theme.svgThemes.fill} !important;
        }
      }
      .button__text {
        color: ${({ theme }) => theme.svgThemes.fill} !important;
      }
    }
  }
  .panel-header {
    background-color: ${({ theme }) => theme.body};
    border-bottom: ${({ theme }) => `1px solid ${theme.textDark}`};
    &__text {
      color: ${({ theme }) => theme.textDark};
    }
    .fab_delete {
      filter: ${({ theme }) => theme.filter};
    }
  }
  .form-panel-form {
    input,.ant-picker, .ant-radio-inner {
      background-color: ${({ theme }) => theme.body};
      color: ${({ theme }) => theme.textDark};
    }
  }
`;
