"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createUniqueGroup = void 0;
var uuid_1 = require("uuid");
/** @deprecated use AttrUtils.generateKey */
// allows to undo-redo consequetive actions of one group
var createUniqueGroup = function (group) {
    if (!group) {
        return null;
    }
    return "".concat(group, "_").concat((0, uuid_1.v4)());
};
exports.createUniqueGroup = createUniqueGroup;
