"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortByNumberField = exports.sortFunc = void 0;
var lodash_1 = require("lodash");
function sortFunc(prev, next) {
    if (prev.order < next.order) {
        return -1;
    }
    if (prev.order > next.order) {
        return 1;
    }
    return 0;
}
exports.sortFunc = sortFunc;
function sortByNumberField(field, asc) {
    if (asc === void 0) { asc = true; }
    return function sortByNumber(prev, next) {
        var prevVal = (0, lodash_1.get)(prev, field, 0);
        var nextVal = (0, lodash_1.get)(next, field, 0);
        var diff = prevVal - nextVal;
        return asc ? diff : -diff;
    };
}
exports.sortByNumberField = sortByNumberField;
