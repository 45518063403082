"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceholderLibraryCreateButton = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var antd_1 = require("antd");
var icons_1 = require("@ant-design/icons");
require("./PlaceholderLibraryCreateButton.less");
var react_redux_1 = require("react-redux");
var rootActionCreators_1 = require("../../../../Root/redux/rootActionCreators/rootActionCreators");
var PlaceholderLibraryCreateButton = function (_a) {
    var props = __rest(_a, []);
    var dispatch = (0, react_redux_1.useDispatch)();
    var currentFolder = props.currentFolder;
    var createFolder = (0, react_1.useCallback)(function () {
        dispatch((0, rootActionCreators_1.setCreatingFolderModal)(true));
    }, []);
    var createPlaceholderTemplate = (0, react_1.useCallback)(function () {
        dispatch((0, rootActionCreators_1.setCreatingPlaceholderModal)(true));
    }, []);
    var kind = currentFolder.kind;
    var menu = ((0, jsx_runtime_1.jsxs)(antd_1.Menu, { children: [(0, jsx_runtime_1.jsx)(antd_1.Menu.Item, __assign({ onClick: createFolder }, { children: "\u041F\u0430\u043F\u043A\u0443" })), kind === 'folder' ? (0, jsx_runtime_1.jsx)(antd_1.Menu.Item, __assign({ onClick: createPlaceholderTemplate }, { children: "\u041F\u043B\u0435\u0439\u0441\u0445\u043E\u043B\u0434\u0435\u0440" })) : null] }));
    return ((0, jsx_runtime_1.jsx)(antd_1.Dropdown, __assign({ overlay: menu, trigger: ['click'], className: 'phadm-dropdown phadm-dropdown_left' }, { children: (0, jsx_runtime_1.jsx)(antd_1.Button, __assign({ type: 'primary', size: 'large', className: 'phadm-create' }, { children: (0, jsx_runtime_1.jsxs)(antd_1.Space, { children: ["\u0421\u043E\u0437\u0434\u0430\u0442\u044C", (0, jsx_runtime_1.jsx)(icons_1.DownOutlined, {})] }) })) })));
};
exports.PlaceholderLibraryCreateButton = PlaceholderLibraryCreateButton;
