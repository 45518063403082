"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformToSection = exports.transformToPlaceholders = void 0;
function transformToPlaceholders(changedPlaceholders, placeholder) {
    var placeholders = placeholder;
    changedPlaceholders.forEach(function (item) {
        var index = placeholders.findIndex(function (obj) { return obj.id === item.id; });
        if (index !== -1) {
            placeholders[index].title = item.name;
            placeholders[index].parent_id = item.parent_id;
            placeholders[index].options = item.options;
            placeholders[index].settings = item.settings;
            placeholders[index].desc = item.description;
            placeholders[index].type = item.type;
            placeholders[index].dataFormat = item.dataFormat;
            placeholders[index].fromSystem = item.fromSystem;
            placeholders[index].formula_id = item.formula_id;
            placeholders[index].format = item.format;
            placeholders[index].isHidden = item.isHidden;
            placeholders[index].isSent = item.isSent;
            placeholders[index].isReceived = item.isReceived;
            placeholders[index].target = item.target;
            placeholders[index].initial_title = item.initial_title;
            placeholders[index].external_params = item.external_params;
            placeholders[index].subType = item.subType;
            placeholders[index].has_relations = item.has_relations;
            placeholders[index].isRequired = item.isRequired;
            placeholders[index].attachmentDocumentID = item.attachmentDocumentID;
            placeholders[index].attachmentTemplateID = item.attachmentTemplateID;
            placeholders[index].fromPlaceholder_id = item.fromPlaceholder_id;
            placeholders[index].params = item.params;
            placeholders[index].isAutonumbering = item.isAutonumbering;
            placeholders[index].dataValue = item.dataValue;
            placeholders[index].customFormulaData = item.customFormulaData;
        }
    });
    return placeholders;
}
exports.transformToPlaceholders = transformToPlaceholders;
function updateBlockModel(index, item, blocks, sections) {
    var _a;
    blocks[index].name = item.name;
    blocks[index].groupName = item.groupName;
    blocks[index].external_params = item.external_params;
    blocks[index].subType = item.subType;
    blocks[index].emptyValue = item.emptyValue;
    blocks[index].isSent = item.isSent;
    blocks[index].isReceived = item.isReceived;
    blocks[index].content.parent_id = item.parent_id;
    blocks[index].content.links = item.settings;
    blocks[index].content.has_relations = item.has_relations;
    blocks[index].isRequired = item.isRequired;
    (_a = item.options) === null || _a === void 0 ? void 0 : _a.forEach(function (option) {
        var ind = sections.findIndex(function (obj) { return obj.id === option.id; });
        if (ind === -1)
            return;
        sections[ind].groupName = option.text;
    });
}
function transformToSection(changedSections, block, section) {
    var blocks = block;
    var sections = section;
    changedSections.forEach(function (item) {
        var index = blocks.findIndex(function (obj) { return obj.id === item.id; });
        if (index !== -1) {
            updateBlockModel(index, item, blocks, sections);
        }
        else {
            index = sections.findIndex(function (obj) { return obj.id === item.id; });
            if (index !== -1) {
                updateBlockModel(index, item, sections, sections);
            }
        }
    });
    return [blocks, sections];
}
exports.transformToSection = transformToSection;
