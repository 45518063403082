"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomFormulaCalculator = exports.Operations = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var CustomFormulaButtons_1 = require("../CustomFormulaButtons/CustomFormulaButtons");
var CustomFormulaResult_1 = require("../CustomFormulaResult/CustomFormulaResult");
var CustomFormulaResultUtils_1 = require("../CustomFormulaResult/CustomFormulaResultUtils");
var icons_1 = require("@ant-design/icons");
var CustomFormulaCalculatorUtils_1 = require("./CustomFormulaCalculatorUtils");
var uuid_1 = require("uuid");
var react_redux_1 = require("react-redux");
var rootGetters_1 = require("../../../../Root/redux/rootGetters/rootGetters");
var antd_1 = require("antd");
var Controls_1 = require("../../../../models/Controls");
var parameterRender = function (parameterAlias, sortedCustomFormulaAggregations, currentFormula) {
    var parameters = currentFormula === null || currentFormula === void 0 ? void 0 : currentFormula.parameters;
    var currentParameter = parameters.find(function (parameter) { return (parameter === null || parameter === void 0 ? void 0 : parameter.alias) === parameterAlias; });
    var placeholderId = currentParameter === null || currentParameter === void 0 ? void 0 : currentParameter.placeholderId;
    var currentAggregation = sortedCustomFormulaAggregations === null || sortedCustomFormulaAggregations === void 0 ? void 0 : sortedCustomFormulaAggregations.find(function (aggregation) { return (aggregation === null || aggregation === void 0 ? void 0 : aggregation.id) === placeholderId; });
    var currentAggregationName = currentAggregation === null || currentAggregation === void 0 ? void 0 : currentAggregation.name;
    var currentAggregationOrder = currentAggregation === null || currentAggregation === void 0 ? void 0 : currentAggregation.order;
    var operandTitle = currentAggregationName && currentAggregationOrder
        ? "".concat(currentAggregationOrder, ". ").concat(currentAggregationName)
        : 'Имя ПХ не определено';
    return ((0, jsx_runtime_1.jsx)(antd_1.Tag, __assign({ title: operandTitle !== null && operandTitle !== void 0 ? operandTitle : '', color: 
        // @ts-ignore
        (CustomFormulaCalculatorUtils_1.AllOperationsValues === null || CustomFormulaCalculatorUtils_1.AllOperationsValues === void 0 ? void 0 : CustomFormulaCalculatorUtils_1.AllOperationsValues.includes(parameterAlias))
            ? 'default'
            : 'green' }, { children: parameterAlias }), (0, uuid_1.v4)()));
};
exports.Operations = [
    CustomFormulaCalculatorUtils_1.AllOperations.Sum,
    CustomFormulaCalculatorUtils_1.AllOperations.Sub,
    CustomFormulaCalculatorUtils_1.AllOperations.Mult,
    CustomFormulaCalculatorUtils_1.AllOperations.Div,
];
var CustomFormulaCalculator = function (_a) {
    var currentFormula = _a.currentFormula, setCurrentFormula = _a.setCurrentFormula, enableOperationResult = _a.enableOperationResult, aggregation = _a.aggregation, updateAggregations = _a.updateAggregations, sortedCustomFormulaAggregations = _a.sortedCustomFormulaAggregations;
    var presentState = (0, react_redux_1.useSelector)(rootGetters_1.getPresentState);
    var templateInfo = presentState === null || presentState === void 0 ? void 0 : presentState.templateInfo;
    var canWriteTemplate = (templateInfo.currentUserControls && Controls_1.Controls.Write) === Controls_1.Controls.Write
        || (templateInfo.currentUserControls && Controls_1.Controls.Ownership) === Controls_1.Controls.Ownership;
    var isEditing = (templateInfo.status === 'Опубликован'
        || templateInfo.status === 'Архив'
        // @ts-ignore
        || (aggregation === null || aggregation === void 0 ? void 0 : aggregation.isLib))
        || !canWriteTemplate;
    var _b = (0, react_1.useState)(false), validationStatus = _b[0], setValidationStatus = _b[1];
    var formulaText = currentFormula === null || currentFormula === void 0 ? void 0 : currentFormula.formula;
    var splittedItems = (0, CustomFormulaResultUtils_1.formulaItemsSplitting)(formulaText);
    var onSaveFormula = function () {
        var filterByLeftBrackets = (splittedItems === null || splittedItems === void 0 ? void 0 : splittedItems.filter(function (item) { return item === (CustomFormulaCalculatorUtils_1.AllOperations === null || CustomFormulaCalculatorUtils_1.AllOperations === void 0 ? void 0 : CustomFormulaCalculatorUtils_1.AllOperations.LeftBr); })) || [];
        var filterByRightBrackets = (splittedItems === null || splittedItems === void 0 ? void 0 : splittedItems.filter(function (item) { return item === (CustomFormulaCalculatorUtils_1.AllOperations === null || CustomFormulaCalculatorUtils_1.AllOperations === void 0 ? void 0 : CustomFormulaCalculatorUtils_1.AllOperations.RightBr); })) || [];
        var isCorrect = (splittedItems === null || splittedItems === void 0 ? void 0 : splittedItems.length) && (splittedItems === null || splittedItems === void 0 ? void 0 : splittedItems.every(function (item, index, arr) {
            var _a, _b;
            if (
            // @ts-ignore
            (exports.Operations === null || exports.Operations === void 0 ? void 0 : exports.Operations.includes(item))
                // @ts-ignore
                && (exports.Operations === null || exports.Operations === void 0 ? void 0 : exports.Operations.includes(arr[index + 1]))
                || (((_a = arr[index]) === null || _a === void 0 ? void 0 : _a.startsWith('p'))
                    && ((_b = arr[index + 1]) === null || _b === void 0 ? void 0 : _b.startsWith('p')))
                || (exports.Operations === null || exports.Operations === void 0 ? void 0 : exports.Operations.includes(arr[(arr === null || arr === void 0 ? void 0 : arr.length) - 1]))
                || (exports.Operations === null || exports.Operations === void 0 ? void 0 : exports.Operations.includes(arr[0]))) {
                return false;
            }
            return true;
        }));
        if ((filterByLeftBrackets === null || filterByLeftBrackets === void 0 ? void 0 : filterByLeftBrackets.length) === (filterByRightBrackets === null || filterByRightBrackets === void 0 ? void 0 : filterByRightBrackets.length) && isCorrect) {
            setValidationStatus(false);
            var _aggregation = __assign(__assign({}, aggregation), { customFormulaData: {
                    formula: currentFormula === null || currentFormula === void 0 ? void 0 : currentFormula.formula,
                    parameters: currentFormula === null || currentFormula === void 0 ? void 0 : currentFormula.parameters,
                } });
            updateAggregations([_aggregation]);
            antd_1.notification.success({
                message: 'Успешно',
                description: 'Пользовательская формула сохранена',
            });
        }
        else {
            setValidationStatus(true);
            antd_1.notification.error({
                message: 'Ошибка',
                description: 'Пользовательская формула некорректна',
            });
        }
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: 'panel__row' }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: 'panel_container' }, { children: [(0, jsx_runtime_1.jsx)(CustomFormulaResult_1.CustomFormulaResult, { currentFormula: currentFormula, setCurrentFormula: setCurrentFormula, validationStatus: validationStatus, isEditing: isEditing }), (0, jsx_runtime_1.jsx)(CustomFormulaButtons_1.CustomFormulaButtons, { currentFormula: currentFormula, setCurrentFormula: setCurrentFormula, enableOperationResult: enableOperationResult, isEditing: isEditing })] })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: 'view_wrapper' }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: 'top' }, { children: [(0, jsx_runtime_1.jsx)("span", __assign({ className: 'note warning' }, { children: "\u0412\u043D\u0438\u043C\u0430\u043D\u0438\u0435! \u041D\u0435\u043E\u0431\u0445\u043E\u0434\u0438\u043C\u043E \u0441\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C \u0432\u0438\u0434 \u0443\u0441\u0442\u0430\u043D\u043E\u0432\u043B\u0435\u043D\u043D\u043E\u0439 \u0444\u043E\u0440\u043C\u0443\u043B\u044B" })), (splittedItems === null || splittedItems === void 0 ? void 0 : splittedItems.length) ? ((0, jsx_runtime_1.jsx)(antd_1.Button, __assign({ type: 'default', danger: validationStatus, size: 'small', title: 'Установить формулу', className: 'save', onClick: onSaveFormula, disabled: isEditing }, { children: (0, jsx_runtime_1.jsx)(icons_1.SaveOutlined, {}) }))) : null] })), (splittedItems === null || splittedItems === void 0 ? void 0 : splittedItems.length)
                        ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("span", __assign({ className: 'note' }, { children: "\u0412\u0430\u0448\u0430 \u0444\u043E\u0440\u043C\u0443\u043B\u0430 \u0431\u0443\u0434\u0435\u0442 \u0432\u044B\u0433\u043B\u044F\u0434\u0435\u0442\u044C \u0442\u0430\u043A:" })), (0, jsx_runtime_1.jsx)("div", __assign({ className: 'bottom' }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: 'formula_items' }, { children: splittedItems === null || splittedItems === void 0 ? void 0 : splittedItems.map(function (item) { return parameterRender(item, sortedCustomFormulaAggregations, currentFormula); }) })) }))] }))
                        : null] }))] })));
};
exports.CustomFormulaCalculator = CustomFormulaCalculator;
