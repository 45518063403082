"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertBlockType = void 0;
var BlockTypes_1 = require("../BlockTypes");
var nodeUtils_1 = require("../utils/nodeUtils");
var textToList_1 = require("./textToList");
var textTypes = [BlockTypes_1.blockTypes.text.type, BlockTypes_1.blockTypes.header2.type];
function convertBlockType(block, newType) {
    if (newType === BlockTypes_1.blockTypes.attachment.type) {
        return {};
    }
    if (newType === BlockTypes_1.blockTypes.repeatedText.type) {
        return { html: (0, nodeUtils_1.createNewParagraph)().outerHTML };
    }
    if (textTypes.includes(block.type) && newType === BlockTypes_1.blockTypes.list.type) {
        return (0, textToList_1.convertBlock)(block.content, 'list');
    }
    if (block.type === BlockTypes_1.blockTypes.list.type && textTypes.includes(newType)) {
        return (0, textToList_1.convertBlock)(block.content, 'text');
    }
    return null;
}
exports.convertBlockType = convertBlockType;
