"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortOptionsAsc = void 0;
function sortOptionsAsc(op1, op2) {
    var _a, _b, _c, _d, _e, _f;
    var order1 = parseFloat((_c = (_b = (_a = op1.order) === null || _a === void 0 ? void 0 : _a.split('_')) === null || _b === void 0 ? void 0 : _b[1]) !== null && _c !== void 0 ? _c : '0');
    var order2 = parseFloat((_f = (_e = (_d = op2.order) === null || _d === void 0 ? void 0 : _d.split('_')) === null || _e === void 0 ? void 0 : _e[1]) !== null && _f !== void 0 ? _f : '0');
    if (order1 > order2) {
        return 1;
    }
    if (order1 < order2) {
        return -1;
    }
    return 0;
}
exports.sortOptionsAsc = sortOptionsAsc;
