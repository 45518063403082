"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setTemplatedListPhsAttrs = exports.getActualPlaceholdersFromDOM = void 0;
/**
 * Получение массива «актуальных» плейсхолдеров
 * @param allPhs Список плейсхолдеров
 * @returns
 */
var getActualPlaceholdersFromDOM = function (allPhs) {
    var actualPlaceholders = allPhs.filter(function (ph) { return document.getElementById("ph_".concat(ph.id)); });
    return actualPlaceholders;
};
exports.getActualPlaceholdersFromDOM = getActualPlaceholdersFromDOM;
var setTemplatedListPhsAttrs = function (placeholders) {
    if (placeholders === void 0) { placeholders = []; }
    placeholders === null || placeholders === void 0 ? void 0 : placeholders.forEach(function (placeholder) {
        var isRequired = placeholder.isRequired, title = placeholder.title, type = placeholder.type, dataFormat = placeholder.dataFormat;
        var placeholderNode = document.getElementById("ph_".concat(placeholder.id));
        if (title) {
            placeholderNode === null || placeholderNode === void 0 ? void 0 : placeholderNode.setAttribute('data-templated-list-name', title);
        }
        if (isRequired) {
            placeholderNode === null || placeholderNode === void 0 ? void 0 : placeholderNode.setAttribute('data-templated-list-required', '1');
        }
        else {
            placeholderNode === null || placeholderNode === void 0 ? void 0 : placeholderNode.setAttribute('data-templated-list-required', '0');
        }
        if (type === 'text' || type === 'directory') {
            placeholderNode === null || placeholderNode === void 0 ? void 0 : placeholderNode.setAttribute('data-templated-list-ph', '1');
            if (dataFormat) {
                placeholderNode === null || placeholderNode === void 0 ? void 0 : placeholderNode.setAttribute('data-placeholder-type', dataFormat);
            }
        }
    });
};
exports.setTemplatedListPhsAttrs = setTemplatedListPhsAttrs;
