"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceholderTemplateSystemSelect = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var compatible_1 = require("@ant-design/compatible");
require("@ant-design/compatible/assets/index.css");
var antd_1 = require("antd");
var lodash_1 = require("lodash");
require("../../AggregationPanel/AggregationPanel.less");
var placeholderValueHelpers_1 = require("../../../../DocumentsPage/utils/placeholderValueHelpers");
var react_redux_1 = require("react-redux");
var rootGetters_1 = require("../../../../Root/redux/rootGetters/rootGetters");
var Option = antd_1.Select.Option;
var PlaceholderTemplateSystemSelect = function (_a) {
    var _b;
    var props = __rest(_a, []);
    var _c = props.index, index = _c === void 0 ? null : _c, _d = props.disabled, disabled = _d === void 0 ? false : _d, _e = props.isOneOfMany, isOneOfMany = _e === void 0 ? false : _e, systemsCatalogue = props.systemsCatalogue, placeholder = props.placeholder, _f = props.onSystemDelete, onSystemDelete = _f === void 0 ? function () {
    } : _f, targetSystem = props.targetSystem, _g = props.onTargetSystemChange, onTargetSystemChange = _g === void 0 ? function () {
    } : _g;
    var presentState = (0, react_redux_1.useSelector)(rootGetters_1.getPresentState);
    var documentKinds = (_b = presentState === null || presentState === void 0 ? void 0 : presentState.data) === null || _b === void 0 ? void 0 : _b.documentKinds;
    var placeholderAdminBreadcrumbs = presentState === null || presentState === void 0 ? void 0 : presentState.placeholderAdminBreadcrumbs;
    var currentTlSystem = placeholderAdminBreadcrumbs === null || placeholderAdminBreadcrumbs === void 0 ? void 0 : placeholderAdminBreadcrumbs.find(function (breadcrumb) { return breadcrumb === null || breadcrumb === void 0 ? void 0 : breadcrumb.system; });
    var findTargetSystemInCatalogue = function () {
        if (!targetSystem
            || !(targetSystem === null || targetSystem === void 0 ? void 0 : targetSystem.system)
            || !systemsCatalogue
            || !systemsCatalogue.length) {
            return null;
        }
        ;
        var saveSystemCatalogue = systemsCatalogue;
        var targetSystemFromCatalogue = saveSystemCatalogue
            .find(function (sys) { return sys.id === (targetSystem === null || targetSystem === void 0 ? void 0 : targetSystem.system); });
        return targetSystemFromCatalogue;
    };
    var findTargetFieldInCatalogue = function (selectedSystemAttributes) {
        if (!selectedSystemAttributes || !selectedSystemAttributes.length)
            return null;
        var selectedField = (0, lodash_1.get)(targetSystem, 'fields', null);
        if (!selectedField)
            return null;
        var fieldFromCatalogue = selectedSystemAttributes
            .find(function (field) { return field.id === selectedField; });
        if (!fieldFromCatalogue)
            return null;
        return (0, lodash_1.get)(fieldFromCatalogue, 'id', null);
    };
    var targetSystemFromCatalogue = findTargetSystemInCatalogue();
    var selectedSystemAttributes = (0, lodash_1.get)(targetSystemFromCatalogue, 'attribute_ids', []);
    var targetSystemValue = (0, lodash_1.get)(targetSystemFromCatalogue, 'id', null);
    var targetSystemField = findTargetFieldInCatalogue(selectedSystemAttributes);
    var dataValue = placeholder && placeholder.dataValue;
    var catalogueSystems = ((currentTlSystem === null || currentTlSystem === void 0 ? void 0 : currentTlSystem.typeKey) === 'templatedList') ? systemsCatalogue === null || systemsCatalogue === void 0 ? void 0 : systemsCatalogue.filter(function (system) { return (system === null || system === void 0 ? void 0 : system.id) === (currentTlSystem === null || currentTlSystem === void 0 ? void 0 : currentTlSystem.system); }) : systemsCatalogue;
    return (0, jsx_runtime_1.jsxs)("div", __assign({ className: [
            'system-select',
            index === 0 && 'first',
            isOneOfMany && 'one-of-many',
        ].join(' ') }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: 'system-select__title' }, { children: "\u0421\u0438\u0441\u0442\u0435\u043C\u0430" })), isOneOfMany && (0, jsx_runtime_1.jsxs)("div", __assign({ className: 'index' }, { children: [index + 1, "."] })), (0, jsx_runtime_1.jsx)(compatible_1.Form.Item, __assign({ validateStatus: !targetSystemValue ? 'error' : undefined, className: 'row_form__item' }, { children: (0, jsx_runtime_1.jsxs)(antd_1.Select, __assign({ disabled: disabled, value: targetSystemValue, onChange: onTargetSystemChange(targetSystem.id, 'system'), className: 'panel__select' }, { children: [(0, jsx_runtime_1.jsx)(Option, __assign({ value: null }, { children: (0, jsx_runtime_1.jsx)("span", __assign({ className: 'default-option' }, { children: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0441\u0438\u0441\u0442\u0435\u043C\u0443 \u0434\u043B\u044F \u0438\u043D\u0442\u0435\u0433\u0440\u0430\u0446\u0438\u0438" })) })), catalogueSystems === null || catalogueSystems === void 0 ? void 0 : catalogueSystems.map(function (system) { return ((0, jsx_runtime_1.jsx)(Option, __assign({ value: system.id }, { children: system.name }), "".concat(targetSystem.id, "-").concat(system.id))); })] })) })), (targetSystem === null || targetSystem === void 0 ? void 0 : targetSystem.system) && selectedSystemAttributes.length !== 0 &&
                (0, jsx_runtime_1.jsx)(compatible_1.Form.Item, __assign({ validateStatus: !targetSystemField ? 'error' : undefined, className: 'row_form__item' }, { children: (0, jsx_runtime_1.jsxs)(antd_1.Select, __assign({ showSearch: true, disabled: disabled, value: targetSystemField, onChange: onTargetSystemChange(targetSystem.id, 'fields'), className: 'panel__select', filterOption: placeholderValueHelpers_1.handleFilterOption }, { children: [(0, jsx_runtime_1.jsx)(Option, __assign({ value: null }, { children: (0, jsx_runtime_1.jsx)("span", __assign({ className: 'default-option' }, { children: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0438\u043C\u044F \u043F\u043E\u043B\u044F" })) })), selectedSystemAttributes === null || selectedSystemAttributes === void 0 ? void 0 : selectedSystemAttributes.map(function (attribute) { return ((0, jsx_runtime_1.jsx)(Option, __assign({ value: attribute.id }, { children: attribute.name }), "".concat(targetSystem.id, "-").concat(attribute.id))); })] })) })), (targetSystem === null || targetSystem === void 0 ? void 0 : targetSystem.system)
                && selectedSystemAttributes.length !== 0
                && targetSystemField === 'sumByDocumentKind'
                &&
                    (0, jsx_runtime_1.jsx)(compatible_1.Form.Item, __assign({ validateStatus: !targetSystemField ? 'error' : undefined, className: 'row_form__item' }, { children: (0, jsx_runtime_1.jsxs)(antd_1.Select, __assign({ showSearch: true, disabled: disabled, className: 'panel__select', value: dataValue, onChange: onTargetSystemChange(targetSystem.id, 'documentKind'), filterOption: placeholderValueHelpers_1.handleFilterOption }, { children: [(0, jsx_runtime_1.jsx)(Option, __assign({ value: null }, { children: (0, jsx_runtime_1.jsx)("span", __assign({ className: 'default-option' }, { children: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0432\u0438\u0434 \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u0430" })) })), documentKinds === null || documentKinds === void 0 ? void 0 : documentKinds.map(function (kind) { return ((0, jsx_runtime_1.jsx)(Option, __assign({ value: kind.guid }, { children: kind.name }), "".concat(targetSystem.id, "-").concat(kind.guid))); })] })) })), !disabled && (0, jsx_runtime_1.jsx)("div", { className: 'remove__icon delete-button', onClick: onSystemDelete(targetSystem.id) })] }), targetSystem.id);
};
exports.PlaceholderTemplateSystemSelect = PlaceholderTemplateSystemSelect;
