"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STYLE_TAG_NAMES = exports.ATTR_NAMES = exports.CLASSES = exports.CLASS_NAMES = void 0;
exports.CLASS_NAMES = {
    // ПХ
    aggregation: 'aggregation',
    editing: 'editing',
    placeholder: 'placeholder',
    creating: 'editing_warning',
    warning: 'warning',
    // разное
    inlineText: 'inline-text',
    listWrapper: 'nested',
    textParagraph: 'text-paragraph',
    // blocks
    block: 'block',
    libBlock: 'library-block',
    authorityBlock: 'block--authority',
    listBlock: 'block--list',
    imageBlock: 'block--image',
    textBlock: 'block--text',
    header2Block: 'block--header2',
    textBlockLeft: 'block--text-left',
    textBlockRight: 'block--text-right',
    tableBlock: 'block--table',
    tableCellBlock: 'block--table-cell',
};
exports.CLASSES = {
    // ПХ
    aggregation: ".".concat(exports.CLASS_NAMES.aggregation),
    editing: ".".concat(exports.CLASS_NAMES.editing),
    placeholder: ".".concat(exports.CLASS_NAMES.placeholder),
    selected: ".".concat(exports.CLASS_NAMES.creating),
    warning: ".".concat(exports.CLASS_NAMES.warning),
    // разное
    inlineText: ".".concat(exports.CLASS_NAMES.inlineText),
    listWrapper: ".".concat(exports.CLASS_NAMES.listWrapper),
    textParagraph: ".".concat(exports.CLASS_NAMES.textParagraph),
    // blocks
    libBlock: ".".concat(exports.CLASS_NAMES.listBlock),
    listBlock: ".".concat(exports.CLASS_NAMES.listBlock),
    textBlock: ".".concat(exports.CLASS_NAMES.textBlock),
    tableBlock: ".".concat(exports.CLASS_NAMES.tableBlock),
};
exports.ATTR_NAMES = {
    paragraph: 'data-is-paragraph',
};
exports.STYLE_TAG_NAMES = ['B', 'I', 'U'];
