"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkBlockDisableNumericDepth = exports.getFirstBlockInSectionByGroup = exports.getFirstBlockInSection = exports.getBlockOrderInSection = void 0;
// Получение порядкового номера блока в секции
var getBlockOrderInSection = function (blockOrder) {
    if (Number.isInteger(blockOrder)) {
        return Number(blockOrder);
    }
    var orderStr = blockOrder.substring(37);
    return Number(orderStr);
};
exports.getBlockOrderInSection = getBlockOrderInSection;
var getFirstBlock = function (blocks) {
    var blocksOrders = blocks.map(function (x) {
        return { block: x, order: (0, exports.getBlockOrderInSection)(x.order) };
    });
    blocksOrders.sort(function (a, b) { return a.order - b.order; });
    return blocksOrders[0].block;
};
// Получение первого блока в секции
var getFirstBlockInSection = function (sectionId, sections) {
    var blocks = sections.filter(function (x) { return x.sectionId === sectionId; });
    if (blocks) {
        return getFirstBlock(blocks);
    }
    return null;
};
exports.getFirstBlockInSection = getFirstBlockInSection;
// Получение первого блока в секции указанной группы(варианта)
var getFirstBlockInSectionByGroup = function (sectionId, groupId, sections) {
    var blocks = sections.filter(function (x) { return x.sectionId === sectionId; }).filter(function (x) { return x.groupId === groupId; });
    if (blocks) {
        return getFirstBlock(blocks);
    }
    return null;
};
exports.getFirstBlockInSectionByGroup = getFirstBlockInSectionByGroup;
// Проверка блока на возможность управлять глубиной его нумерации
var checkBlockDisableNumericDepth = function (blockId, blocks, sections) {
    var _a, _b;
    if (blockId) {
        var block_1 = (_a = blocks.find(function (x) { return x.id === blockId; })) !== null && _a !== void 0 ? _a : sections.find(function (x) { return x.id === blockId; });
        if (block_1) {
            // Нельзя управлять уровнем нумерации для любых списков из библиотек (сбивается нумерация)
            if (block_1.type && block_1.type === 'list' && block_1.templateElement_id) {
                return true;
            }
            // Нельзя управлять уровнем нумерации для блока вариативности которая является дочерней
            // по отношений к другой вариативности и эта родительская вариативность имеет только один вариант и при том расположена самой первой (DC-4065)
            if (block_1.sectionId) {
                var section_1 = (_b = sections.find(function (x) { return x.id === block_1.sectionId; })) !== null && _b !== void 0 ? _b : blocks.find(function (x) { return x.id === block_1.sectionId; });
                // Сама секция имеет sectionId а значит является "встроенной"
                if (section_1 && section_1.sectionId) {
                    var parentSection = blocks.find(function (x) { return x.id === section_1.sectionId; });
                    // Проверяем не больше двух уровней
                    if (parentSection &&
                        (parentSection.subType !== 'multi' || parentSection.sectionVariant === 'multi') &&
                        Number(parentSection.order) === 0) {
                        return true;
                    }
                }
            }
        }
    }
    return false;
};
exports.checkBlockDisableNumericDepth = checkBlockDisableNumericDepth;
