"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextUtils = void 0;
var constants_1 = require("../../constants");
var TypeUtils_1 = require("./TypeUtils");
var TextUtils = /** @class */ (function () {
    function TextUtils() {
    }
    TextUtils.removeZwnj = function (value) {
        var _a;
        var text = '';
        if (TypeUtils_1.TypeUtils.isElement(value)) {
            text = value.innerHTML;
        }
        if (TypeUtils_1.TypeUtils.isTextNode(value)) {
            text = (_a = value.textContent) !== null && _a !== void 0 ? _a : '';
        }
        if (typeof value === 'string') {
            text = value;
        }
        return text.replaceAll(constants_1.U_ZWNJ, '');
    };
    TextUtils.fixZwnj = function (value) {
        return "".concat(constants_1.U_ZWNJ).concat(TextUtils.removeZwnj(value));
    };
    TextUtils.getSelectedText = function () {
        var _a, _b;
        return (_b = (_a = window.getSelection()) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '';
    };
    TextUtils.createPlaceholderTitle = function (placeholder) {
        return "".concat(placeholder.order, ". ").concat(placeholder.title);
    };
    return TextUtils;
}());
exports.TextUtils = TextUtils;
