"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.placeholderValueDisabled = exports.sortOrderUnfilledPhs = exports.addTlUnfilledPhs = exports.isEmptyCascaderValue = exports.isEmptyTLDictionaryPh = exports.isEmptyAttachmentValue = exports.isEmptyTemplatedListValue = exports.isEmptyValue = void 0;
var isRequired = function (placeholder) {
    var result = placeholder && placeholder.isRequired && placeholder.type !== 'link';
    return result;
};
var isEmptyValue = function (placeholder, placeholderValue) {
    if (!isRequired(placeholder)) {
        return false;
    }
    if (placeholderValue === null || placeholderValue === void 0 ? void 0 : placeholderValue.disabled) {
        return false;
    }
    var isEmptyPlaceholder = !placeholderValue || !placeholderValue.value || !placeholderValue.value.replace(/\s/g, '');
    if (isEmptyPlaceholder) {
        return true;
    }
    return false;
};
exports.isEmptyValue = isEmptyValue;
var isEmptyTemplatedListValue = function (ph, placeholdersValue, sections, templatedListBlocks) {
    if (sections === void 0) { sections = []; }
    var phTlId = ph.id;
    var findRelationValues = placeholdersValue === null || placeholdersValue === void 0 ? void 0 : placeholdersValue.filter(function (valExmplar) { var _a; return ((_a = valExmplar.extendedAttributes) === null || _a === void 0 ? void 0 : _a.TemplatedListPlaceholderId) === phTlId; });
    var docPlaceholders = document.querySelectorAll('.placeholder');
    var convertPhArray = Array.from(docPlaceholders);
    // сделать фильтр по findRelationValues что эти значения в дом(для работы с секциями в списках)
    // @ts-ignore
    var currentRelationValues = findRelationValues === null || findRelationValues === void 0 ? void 0 : findRelationValues.filter(function (phVal) {
        return convertPhArray.find(function (elem) { return elem.dataset.placeholder === phVal.placeholder_id; });
    });
    var isRequiredPlaceholderValues = currentRelationValues === null || currentRelationValues === void 0 ? void 0 : currentRelationValues.filter(function (val) {
        var _a;
        var isRequired = true;
        var currentPlaceholderId = val === null || val === void 0 ? void 0 : val.placeholder_id;
        // @ts-ignore
        var currentPlaceholderNode = (_a = __spreadArray([], docPlaceholders, true)) === null || _a === void 0 ? void 0 : _a.find(function (phDOM) { return phDOM.getAttribute('data-placeholder') === currentPlaceholderId; });
        if (currentPlaceholderNode) {
            var templatedListRequired = currentPlaceholderNode.getAttribute('data-templated-list-required');
            if (templatedListRequired) {
                var isTemplatedListRequired = Number(templatedListRequired) === 1;
                isRequired = isTemplatedListRequired;
            }
        }
        // запишем в экземпляры, которые стоит рассматривать в дальнейшем, т.е. те, что идут как обязательные поля
        if (isRequired) {
            return val;
        }
    });
    var placeholderValuesCheck = isRequiredPlaceholderValues === null || isRequiredPlaceholderValues === void 0 ? void 0 : isRequiredPlaceholderValues.map(function (val) { var _a; return (_a = val === null || val === void 0 ? void 0 : val.values) === null || _a === void 0 ? void 0 : _a.every(function (v) { return v !== ''; }); });
    var placeholderValuesCheckResult = placeholderValuesCheck === null || placeholderValuesCheck === void 0 ? void 0 : placeholderValuesCheck.every(function (value) { return value === true; });
    return !placeholderValuesCheckResult;
};
exports.isEmptyTemplatedListValue = isEmptyTemplatedListValue;
var isEmptyAttachmentValue = function (isSelectedPDC, placeholder, placeholderValue) {
    if (!isRequired(placeholder)) {
        return false;
    }
    if (!placeholder.dataFormat)
        return false;
    var isAttachmentFixed = ['fixedPDC', 'fixedPlan'].includes(placeholder.dataFormat);
    if (isAttachmentFixed && isSelectedPDC) {
        return false;
    }
    if (!placeholderValue) {
        return true;
    }
    if (placeholder.dataFormat === 'fromTemplate' &&
        placeholderValue.createdDocumentID &&
        placeholderValue.createdDocumentID.file_key) {
        return false;
    }
    return (0, exports.isEmptyValue)(placeholder, placeholderValue);
};
exports.isEmptyAttachmentValue = isEmptyAttachmentValue;
var isEmptyTLDictionaryPh = function (placeholder) {
    if (!placeholder.isRequired) {
        return false;
    }
    return !(placeholder.value && placeholder.isRequired);
};
exports.isEmptyTLDictionaryPh = isEmptyTLDictionaryPh;
var isEmptyCascaderValue = function (placeholder, placeholderValue) {
    if (!isRequired(placeholder)) {
        return false;
    }
    if (!placeholderValue) {
        return true;
    }
    if (placeholderValue === null || placeholderValue === void 0 ? void 0 : placeholderValue.disabled) {
        return false;
    }
    if (placeholder.options && placeholderValue.optionId) {
        var option = placeholder.options.find(function (x) { return x.id === placeholderValue.optionId; });
        if (option === null || option === void 0 ? void 0 : option.emptyValue) {
            return false;
        }
        if ((option === null || option === void 0 ? void 0 : option.userInput) && isRequired(placeholder) && placeholder.value) {
            return false;
        }
    }
    return (0, exports.isEmptyValue)(placeholder, placeholderValue);
};
exports.isEmptyCascaderValue = isEmptyCascaderValue;
var addTlUnfilledPhs = function (result, templatedListPlaceholders, placeholdersValue, sections, templatedListBlocks) {
    if (result === void 0) { result = []; }
    if (placeholdersValue === void 0) { placeholdersValue = []; }
    var Tls = (templatedListPlaceholders === null || templatedListPlaceholders === void 0 ? void 0 : templatedListPlaceholders.filter(function (ph) {
        return (0, exports.isEmptyTemplatedListValue)(ph, placeholdersValue, sections, templatedListBlocks);
    })) || [];
    result.push.apply(result, Tls);
    return result;
};
exports.addTlUnfilledPhs = addTlUnfilledPhs;
var findAllNestedBlocks = function (blocks, sections) {
    var allBlocks = [];
    blocks === null || blocks === void 0 ? void 0 : blocks.forEach(function (block) {
        if ((block === null || block === void 0 ? void 0 : block.type) === 'section') {
            var blockId_1 = block === null || block === void 0 ? void 0 : block.id;
            var searchFromSections = sections === null || sections === void 0 ? void 0 : sections.filter(function (section) { return (section === null || section === void 0 ? void 0 : section.sectionId) === blockId_1; });
            if (searchFromSections === null || searchFromSections === void 0 ? void 0 : searchFromSections.length) {
                allBlocks.push.apply(allBlocks, searchFromSections);
            }
        }
        else {
            allBlocks.push(block);
        }
    });
    // проверка если присутствуют вложенные секции
    if ((allBlocks === null || allBlocks === void 0 ? void 0 : allBlocks.length) && (allBlocks === null || allBlocks === void 0 ? void 0 : allBlocks.some(function (block) { return (block === null || block === void 0 ? void 0 : block.type) === 'section'; }))) {
        return findAllNestedBlocks(allBlocks, sections);
    }
    else {
        return allBlocks;
    }
};
var sortOrderUnfilledPhs = function (blocks, result, sections) {
    if (blocks === void 0) { blocks = []; }
    if (result === void 0) { result = []; }
    if (sections === void 0) { sections = []; }
    var sortedResult = [];
    var foundBlocks = findAllNestedBlocks(blocks, sections);
    // сортируем все незаполненные пх по порядку блоков в полотне документа
    var sortBlocksWithPhs = foundBlocks === null || foundBlocks === void 0 ? void 0 : foundBlocks.filter(function (block) { return result === null || result === void 0 ? void 0 : result.some(function (res) { return res.elementId === block.id; }); });
    sortBlocksWithPhs === null || sortBlocksWithPhs === void 0 ? void 0 : sortBlocksWithPhs.forEach(function (sortRes) {
        var findPhs = result === null || result === void 0 ? void 0 : result.filter(function (ph) { return ph.elementId === sortRes.id; });
        findPhs === null || findPhs === void 0 ? void 0 : findPhs.sort(function (a, b) { return a.order - b.order; });
        sortedResult.push.apply(sortedResult, findPhs);
    });
    return sortedResult;
};
exports.sortOrderUnfilledPhs = sortOrderUnfilledPhs;
var placeholderValueDisabled = function (placeholder, placeholdersValue) {
    var placeholderId = placeholder.id;
    var currentPlaceholderValue = placeholdersValue.find(function (placeholderValue) { return placeholderValue.placeholder_id === placeholderId; });
    var disabled = currentPlaceholderValue === null || currentPlaceholderValue === void 0 ? void 0 : currentPlaceholderValue.disabled;
    return disabled;
};
exports.placeholderValueDisabled = placeholderValueDisabled;
