"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceholderLibraryDashboard = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_redux_1 = require("react-redux");
var PlaceholderLibraryContent_1 = require("../PlaceholderLibraryContent/PlaceholderLibraryContent");
var PlaceholderTemplateFolderApi_1 = require("../../../../api/placeholderLibraryApi/PlaceholderTemplateFolder/PlaceholderTemplateFolderApi");
var react_router_dom_1 = require("react-router-dom");
var antd_1 = require("antd");
var rootGetters_1 = require("../../../../Root/redux/rootGetters/rootGetters");
var rootActionCreators_1 = require("../../../../Root/redux/rootActionCreators/rootActionCreators");
var Search = antd_1.Input.Search;
var PlaceholderLibraryDashboard = function (_a) {
    var props = __rest(_a, []);
    var templateType = props.templateType, currentFolder = props.currentFolder;
    var dispatch = (0, react_redux_1.useDispatch)();
    var history = (0, react_router_dom_1.useHistory)();
    var historyLocationState = history.location.state;
    var currentFolderId = historyLocationState === null || historyLocationState === void 0 ? void 0 : historyLocationState.folderId;
    var presentState = (0, react_redux_1.useSelector)(rootGetters_1.getPresentState);
    var placeholderTemplateFolderContentObj = presentState === null || presentState === void 0 ? void 0 : presentState.placeholderTemplateFolderContent;
    var onSearch = function (value, _e, info) {
        var name = value || '';
        if (currentFolderId) {
            if (name) {
                (0, PlaceholderTemplateFolderApi_1.placeholderTemplateStructureSearch)({ folderId: currentFolderId, pattern: name }).then(function (response) { return dispatch((0, rootActionCreators_1.setPlaceholderTemplateFolderContent)(response)); });
            }
            else {
                (0, PlaceholderTemplateFolderApi_1.placeholderTemplateFolderContent)({ id: currentFolderId }).then(function (response) { return dispatch((0, rootActionCreators_1.setPlaceholderTemplateFolderContent)(response)); });
            }
        }
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: 'phadm-site-layout-background', style: { padding: 30, minHeight: "calc(".concat(100, "vh - 150px)") } }, { children: [(0, jsx_runtime_1.jsx)(Search, { placeholder: '\u041F\u043E\u0438\u0441\u043A \u041F\u0425 \u0438\u043B\u0438 \u043F\u0430\u043F\u043A\u0438', onSearch: onSearch, enterButton: true, style: { marginBottom: 18 } }), (0, jsx_runtime_1.jsx)(PlaceholderLibraryContent_1.PlaceholderLibraryContent, { folderContent: placeholderTemplateFolderContentObj, templateType: templateType, currentFolder: currentFolder })] })));
};
exports.PlaceholderLibraryDashboard = PlaceholderLibraryDashboard;
