"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceholderLibraryBreadcrumbs = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var placeholdersLibraryUtils_1 = require("../../../../Root/utils/placeholdersLibraryUtils");
var routes_1 = require("../../../../routes");
var react_router_dom_1 = require("react-router-dom");
var rootGetters_1 = require("../../../../Root/redux/rootGetters/rootGetters");
var rootActionCreators_1 = require("../../../../Root/redux/rootActionCreators/rootActionCreators");
var PlaceholderLibraryBreadcrumbs = function (_a) {
    var props = __rest(_a, []);
    var folders = props.folders;
    var dispatch = (0, react_redux_1.useDispatch)();
    var history = (0, react_router_dom_1.useHistory)();
    var historyLocationState = history.location.state;
    var presentState = (0, react_redux_1.useSelector)(rootGetters_1.getPresentState);
    var breadcrumbs = presentState === null || presentState === void 0 ? void 0 : presentState.placeholderAdminBreadcrumbs;
    var breadcrumbTransition = (0, react_1.useCallback)(function (breadcrumb) {
        var id = breadcrumb.id;
        var breadcrumbsAlias = (0, placeholdersLibraryUtils_1.createBreadcrumbsAlias)(id, folders) || [];
        dispatch((0, rootActionCreators_1.setPlaceholderAdminBreadcrumbs)(breadcrumbsAlias));
        var lastExpandedKey = id;
        var createHistoryState = __assign(__assign({}, historyLocationState), { folderId: "".concat(id) });
        history.push(routes_1.routeGenerator.placeholderLibraryFolderEditLink("".concat(lastExpandedKey)), createHistoryState);
    }, [historyLocationState, folders]);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (breadcrumbs === null || breadcrumbs === void 0 ? void 0 : breadcrumbs.length)
            ?
                (0, jsx_runtime_1.jsx)(antd_1.Breadcrumb, __assign({ style: { margin: '15px 0' } }, { children: breadcrumbs === null || breadcrumbs === void 0 ? void 0 : breadcrumbs.map(function (breadcrumb) { return (0, jsx_runtime_1.jsx)(antd_1.Breadcrumb.Item, { children: (0, jsx_runtime_1.jsx)("span", __assign({ onClick: function () { return breadcrumbTransition(breadcrumb); } }, { children: breadcrumb === null || breadcrumb === void 0 ? void 0 : breadcrumb.name })) }); }) }))
            : null }));
};
exports.PlaceholderLibraryBreadcrumbs = PlaceholderLibraryBreadcrumbs;
