"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setCaretToElement = exports.restoreSelection = exports.saveSelection = exports.getCaret = exports.getCaretActiveElement = exports.getCaretCharacterOffsetWithin = exports.setCaretStartPosition = exports.setCaretEndPosition = exports.getFirstNodeForCaret = exports.getLastNodeForCaret = exports.setCaretPosition = exports.getMaxCaretPosition = void 0;
var common_1 = require("../../../utils/common");
var nodeUtils_1 = require("./nodeUtils");
function getMaxCaretPosition(target) {
    var _a, _b;
    return (_b = (_a = target === null || target === void 0 ? void 0 : target.textContent) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 1;
}
exports.getMaxCaretPosition = getMaxCaretPosition;
function setCaretPosition(node, position) {
    if (!common_1.TypeUtils.isNode(node)) {
        return;
    }
    try {
        var range = document.createRange();
        var sel = window.getSelection();
        if (sel && range) {
            range.setStart(node, position);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
        }
    }
    catch (e) {
        console.warn(e);
    }
}
exports.setCaretPosition = setCaretPosition;
function getLastNodeForCaret(node) {
    if (!common_1.TypeUtils.isElement(node)) {
        return node;
    }
    var count = node.childNodes.length;
    if (count === 1) {
        return node.childNodes[0];
    }
    return getLastNodeForCaret(node.childNodes[count - 1]);
}
exports.getLastNodeForCaret = getLastNodeForCaret;
function getFirstNodeForCaret(node) {
    if (!common_1.TypeUtils.isElement(node) || !node.firstChild) {
        return node;
    }
    var firstChild = node.firstChild;
    if (!firstChild.firstChild) {
        return firstChild;
    }
    return getLastNodeForCaret(firstChild.firstChild);
}
exports.getFirstNodeForCaret = getFirstNodeForCaret;
function setCaretEndPosition(target, position) {
    if (!common_1.TypeUtils.isNode(target)) {
        return;
    }
    var node = getLastNodeForCaret(target);
    setCaretPosition(node, position !== null && position !== void 0 ? position : getMaxCaretPosition(node));
}
exports.setCaretEndPosition = setCaretEndPosition;
function setCaretStartPosition(target, position) {
    if (position === void 0) { position = 1; }
    if (!common_1.TypeUtils.isNode(target)) {
        return;
    }
    setCaretPosition(getFirstNodeForCaret(target), position);
}
exports.setCaretStartPosition = setCaretStartPosition;
// возвращает количество символов от начала элемента до текущего положения каретки
// https://stackoverflow.com/questions/4811822/get-a-ranges-start-and-end-offsets-relative-to-its-parent-container
/** @deprecated use getCaretCharacterOffset */
function getCaretCharacterOffsetWithin(element) {
    var caretOffset = 0;
    if (!element)
        return caretOffset;
    var doc = element.ownerDocument || element.document;
    var win = doc.defaultView || doc.parentWindow;
    var sel;
    if (typeof win.getSelection !== 'undefined') {
        sel = win.getSelection();
        if (sel.rangeCount > 0) {
            var range = win.getSelection().getRangeAt(0);
            var preCaretRange = range.cloneRange();
            preCaretRange.selectNodeContents(element);
            preCaretRange.setEnd(range.endContainer, range.endOffset);
            caretOffset = preCaretRange.toString().length;
        }
    }
    else if (sel === doc.selection && sel.type !== 'Control') {
        var textRange = sel.createRange();
        var preCaretTextRange = doc.body.createTextRange();
        preCaretTextRange.moveToElementText(element);
        preCaretTextRange.setEndPoint('EndToEnd', textRange);
        caretOffset = preCaretTextRange.text.length;
    }
    return caretOffset;
}
exports.getCaretCharacterOffsetWithin = getCaretCharacterOffsetWithin;
function getCaretActiveElement(focusedElement, cloneChild) {
    if (cloneChild === void 0) { cloneChild = false; }
    var caretPosition = 0;
    var child;
    var sel = window.getSelection();
    if (sel && sel.rangeCount) {
        var range = sel.getRangeAt(0);
        if (focusedElement.contains(range.commonAncestorContainer)) {
            child = range.commonAncestorContainer;
            caretPosition = range.endOffset;
        }
    }
    return !child
        ? null
        : {
            el: focusedElement,
            child: cloneChild ? child.cloneNode(true) : child,
            pos: caretPosition,
            offset: (0, nodeUtils_1.getCaretCharacterOffset)(focusedElement),
        };
}
exports.getCaretActiveElement = getCaretActiveElement;
/** @deprecated use getCaretActiveElement */
function getCaret(editableEl, cloneChild) {
    if (cloneChild === void 0) { cloneChild = false; }
    var caretPos = 0;
    var sel;
    var range;
    var child;
    if (window.getSelection) {
        sel = window.getSelection();
        if (sel.rangeCount) {
            range = sel.getRangeAt(0);
            if (editableEl.contains(range.commonAncestorContainer)) {
                child = range.commonAncestorContainer;
                caretPos = range.endOffset;
            }
        }
    }
    else if (document.selection && document.selection.createRange) {
        range = document.selection.createRange();
        if (range.parentElement() === editableEl) {
            var tempEl = document.createElement('span');
            editableEl.insertBefore(tempEl, editableEl.firstChild);
            var tempRange = range.duplicate();
            tempRange.moveToElementText(tempEl);
            tempRange.setEndPoint('EndToEnd', range);
            child = tempEl;
            caretPos = tempRange.text.length;
        }
    }
    if (!child) {
        return null;
    }
    return {
        el: editableEl,
        child: cloneChild ? child.cloneNode(true) : child,
        pos: caretPos,
        offset: getCaretCharacterOffsetWithin(editableEl),
    };
}
exports.getCaret = getCaret;
// for current caret/selection position
function saveSelection() {
    if (window.getSelection) {
        var sel = window.getSelection();
        if ((sel === null || sel === void 0 ? void 0 : sel.getRangeAt) && sel.rangeCount && !!sel.toString().length) {
            return sel.getRangeAt(0).cloneRange();
        }
    }
    else if (document.selection && document.selection.createRange) {
        return document.selection.createRange().cloneRange();
    }
    return null;
}
exports.saveSelection = saveSelection;
function restoreSelection(range) {
    if (range) {
        if (window.getSelection) {
            var sel = window.getSelection();
            sel === null || sel === void 0 ? void 0 : sel.removeAllRanges();
            sel === null || sel === void 0 ? void 0 : sel.addRange(range);
        }
        else if (document.selection && range.select) {
            range.select();
        }
    }
}
exports.restoreSelection = restoreSelection;
function setCaretToElement(id) {
    var elem = document.getElementById(id === null || id === void 0 ? void 0 : id.toString());
    if (elem == null) {
        return null;
    }
    if (elem.createTextRange) {
        var range = elem.createTextRange();
        range.move('character', 0);
        range.select();
    }
    else if (elem.selectionStart) {
        elem.focus();
        elem.setSelectionRange(0, 0);
    }
    else if (elem.setActive) {
        elem.setActive();
    }
    else {
        elem.focus();
    }
    return elem;
}
exports.setCaretToElement = setCaretToElement;
