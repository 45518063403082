"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.emptyNotRequiredPlaceholders = exports.handleFilterOption = exports.templatedListPlaceholdersValueToDom = exports.findPlaceholdersOnPage = exports.updatePlaceholderDom = exports.removeDotExceptLast = exports.setMoneyValueFormat = exports.returnPlaceholderPayload = exports.isPlaceholderBankAcc = exports.parseExternalOptions = exports.resolvePlaceholderValue = exports.resolveTemplatedListPlaceholderValue = void 0;
var constants_1 = require("../../shared/utils/constants");
var common_1 = require("../../shared/utils/common");
// placeholderValue имеет следующую структуру:
// {
//   placeholder_id: идентификатор плейсхолдера,
//   value:
//    примитивное значение, если плейсхолдер - не повторяющийся
//    [{
//       id: placeholder_id || placeholder_id_i - идентификатор i-го клона пх
//       value: значение клона
//      },
//    ] {...},
//   optionId,
//   attachmentDocumentID,
//   createdDocumentID,
// }
function findRepeatedValue(phId, parentValue) {
    try {
        if (!phId || !parentValue || !parentValue.value)
            return '';
        var valueObj = JSON.parse(parentValue.value).find(function (o) { return o.id === phId; });
        if (!valueObj)
            return '';
        return valueObj.value;
    }
    catch (err) {
        console.error(err);
        return '';
    }
}
function setRepeatedValue(placeholder, value, placeholderValues) {
    if (!placeholder || !placeholder.id || !placeholderValues)
        return null;
    // phId - идентификатор пх, который был клонирован
    var phId = placeholder.id.split('_')[0];
    var placeholderValue = placeholderValues.find(function (val) { return val.placeholder_id === phId; });
    var repeatedValueArray = [];
    if (placeholderValue && placeholderValue.value) {
        repeatedValueArray = JSON.parse(placeholderValue.value);
    }
    if (repeatedValueArray.find(function (o) { return o['id'] === placeholder.id; })) {
        return repeatedValueArray.map(function (o) {
            if (o['id'] === placeholder.id)
                return __assign(__assign({}, o), { value: value });
            return o;
        });
    }
    return __spreadArray(__spreadArray([], repeatedValueArray, true), [
        {
            id: placeholder.id,
            value: value,
        },
    ], false);
}
function resolveTemplatedListPlaceholderValue(searchPlaceholderValue, textPhIndex) {
    if (textPhIndex) {
        var values = searchPlaceholderValue === null || searchPlaceholderValue === void 0 ? void 0 : searchPlaceholderValue.values;
        var index = Number(textPhIndex);
        return values[index];
    }
    else {
        return '';
    }
}
exports.resolveTemplatedListPlaceholderValue = resolveTemplatedListPlaceholderValue;
function resolvePlaceholderValue(placeholder, values) {
    var placeholderId = placeholder.id;
    if (placeholder.subType === 'repeated') {
        // id повторяющегося плейсхолдера содержит постфикс _
        placeholderId = placeholderId.split('_')[0];
    }
    var result = values.find(function (val) { return val.placeholder_id === placeholderId; });
    if (!result) {
        return '';
    }
    if (placeholder.subType === 'repeated') {
        return findRepeatedValue(placeholder.id, result);
    }
    return result.value;
}
exports.resolvePlaceholderValue = resolvePlaceholderValue;
function parseExternalOptions(placeholder, documentId) {
    try {
        if (!documentId)
            return [];
        var parsedUdmsOptions = typeof placeholder.udmsOptions === 'string' ? JSON.parse(placeholder.udmsOptions) : placeholder.udmsOptions;
        return (parsedUdmsOptions && parsedUdmsOptions.filter(function (x) { return x.documentId === documentId; })) || [];
    }
    catch (err) {
        console.error(err);
        return [];
    }
}
exports.parseExternalOptions = parseExternalOptions;
function isPlaceholderBankAcc(placeholder) {
    var dataFormat = placeholder.dataFormat;
    return !!dataFormat && typeof dataFormat === 'string' && dataFormat.toUpperCase().includes('BANKACCNUM');
}
exports.isPlaceholderBankAcc = isPlaceholderBankAcc;
function getValueString(value) {
    if (value === undefined || value === null) {
        return value;
    }
    return String(value);
}
function returnPlaceholderPayload(placeholder, value, elementId, placeholdersValue, attachmentDocumentID, createdDocumentID, templatedListPlaceholderId, valuesArray) {
    var _a, _b;
    if (attachmentDocumentID === void 0) { attachmentDocumentID = null; }
    if (createdDocumentID === void 0) { createdDocumentID = null; }
    if (templatedListPlaceholderId === void 0) { templatedListPlaceholderId = ''; }
    if (valuesArray === void 0) { valuesArray = []; }
    var documentId = location.pathname.split('/')[2];
    var placeholderOptions = (constants_1.PLACEHOLDER_OPTION_TYPES === null || constants_1.PLACEHOLDER_OPTION_TYPES === void 0 ? void 0 : constants_1.PLACEHOLDER_OPTION_TYPES.includes(placeholder === null || placeholder === void 0 ? void 0 : placeholder.type))
        ? parseExternalOptions(placeholder, documentId)
        : placeholder.options;
    if ((_a = placeholder.TLUdmsOptions) === null || _a === void 0 ? void 0 : _a.length) {
        placeholderOptions = placeholder.TLUdmsOptions;
    }
    var selectedOption = placeholderOptions === null || placeholderOptions === void 0 ? void 0 : placeholderOptions.find(function (opt) { return opt.id === value; });
    if (!selectedOption) {
        selectedOption = placeholderOptions === null || placeholderOptions === void 0 ? void 0 : placeholderOptions.find(function (opt) { return opt.text === value; });
    }
    var selectedOptionId = selectedOption ? selectedOption.id : '';
    var valueToSet = value;
    if (selectedOption) {
        var isBankAccType = isPlaceholderBankAcc(placeholder);
        var optionValue = isBankAccType ? selectedOption.id : selectedOption.text;
        valueToSet = !selectedOption.emptyValue ? optionValue : '';
        if (selectedOption.userInput) {
            valueToSet = value === selectedOption.id ? constants_1.defaultInputValues.userInput : value;
            if (placeholder.isRequired) {
                valueToSet = '';
            }
        }
    }
    var phId = placeholder.id;
    if (placeholder.subType === 'repeated') {
        phId = phId.split('_')[0];
        valueToSet = setRepeatedValue(placeholder, getValueString(valueToSet), placeholdersValue);
    }
    // случай, когда мы намеренно очищаем placeholderValue
    if (value === null && !selectedOptionId) {
        selectedOptionId = null;
    }
    else if (!selectedOptionId) {
        var placeValue = placeholdersValue.find(function (obj) { return obj.placeholder_id === placeholder.id; });
        if (placeValue) {
            selectedOptionId = placeValue.optionId;
        }
    }
    // const isValueEmpty = valueToSet === null || valueToSet === undefined;
    if (value && value.isUserInput) {
        valueToSet = value.textValue;
        selectedOptionId = value.optionId;
    }
    var values = [];
    if (templatedListPlaceholderId) {
        var _c = phId.split('_'), id = _c[0], indx = _c[1];
        phId = id;
        var searchCurrentPhValue = placeholdersValue === null || placeholdersValue === void 0 ? void 0 : placeholdersValue.find(function (ph) { return ph.placeholder_id === phId; });
        var newValue = value;
        if ((_b = placeholder.TLUdmsOptions) === null || _b === void 0 ? void 0 : _b.length) {
            newValue = valueToSet;
        }
        if (searchCurrentPhValue) {
            // @ts-ignore
            values = searchCurrentPhValue.values;
            if (newValue === null || newValue === undefined) {
                newValue = '';
            }
            // @ts-ignore
            values[indx] = "".concat(newValue);
        }
        else {
            values.length = valuesArray.length;
            // @ts-ignore
            values.fill('');
            // @ts-ignore
            values[indx] = "".concat(newValue);
        }
    }
    var payload = [
        {
            placeholder_id: phId,
            value: getValueString(valueToSet),
            optionId: selectedOptionId,
            attachmentDocumentID: attachmentDocumentID,
            createdDocumentID: createdDocumentID,
            templatedListPlaceholderId: templatedListPlaceholderId,
            values: values,
        },
    ];
    Object.keys(placeholder.settings).forEach(function (key) {
        var newValue = '';
        var newId = '';
        if (placeholder.settings[key] && placeholder.settings[key][selectedOptionId]) {
            // пока плейсхолдеры не имеют мультивыбора
            var parentOption = placeholder.settings[key][selectedOptionId][0];
            if (parentOption) {
                newValue = parentOption.emptyValue ? '' : parentOption.text;
                newId = parentOption.id;
            }
        }
        var placeholderId = key;
        var newArray = {
            placeholder_id: placeholderId,
            value: getValueString(newValue),
            optionId: newId,
            attachmentDocumentID: attachmentDocumentID,
            createdDocumentID: createdDocumentID,
            templatedListPlaceholderId: templatedListPlaceholderId,
            values: values,
        };
        payload.push(newArray);
    });
    return payload; // TODO: payload type should be equal PlaceholdersValue type
}
exports.returnPlaceholderPayload = returnPlaceholderPayload;
function setMoneyValueFormat(value) {
    if (value !== null && value !== '') {
        value = value.replaceAll(',', '.');
        value = value.replaceAll(/\s/g, '');
        value = value.replaceAll(/[^\d.-]/g, '');
        value = removeDotExceptLast(value);
        value = +value;
        value = value.toFixed(2);
    }
    else {
        value = '';
    }
    return value;
}
exports.setMoneyValueFormat = setMoneyValueFormat;
function removeDotExceptLast(inputString) {
    var lastCommaIndex = inputString.lastIndexOf('.');
    if (lastCommaIndex > -1) {
        return inputString.substring(0, lastCommaIndex).replaceAll('.', '') + inputString.substring(lastCommaIndex);
    }
    else {
        return inputString;
    }
}
exports.removeDotExceptLast = removeDotExceptLast;
function updatePlaceholderDom(placeholder, placeholderValue, placeholders) {
    var _a;
    if (!placeholder || !placeholderValue)
        return;
    if (placeholder.subType === 'repeated') {
        var repeatedValuesArray = [];
        try {
            repeatedValuesArray = JSON.parse(placeholderValue.value);
        }
        catch (err) {
            console.error(err);
        }
        repeatedValuesArray.forEach(function (o) {
            var node = document.getElementById("ph_".concat(o['id']));
            if (node !== null && node.innerText !== o['value']) {
                node.innerText = o['value'] || '';
            }
        });
        return;
    }
    var node = document.getElementById("ph_".concat(placeholderValue.placeholder_id));
    var newVal = placeholder && (placeholder.type === 'attachment' || placeholder.subType === 'boundary')
        ? placeholder.title
        : placeholderValue.value;
    if (isPlaceholderBankAcc(placeholder)) {
        newVal = placeholderValue.value;
    }
    if (placeholder === null || placeholder === void 0 ? void 0 : placeholder.customFormulaData) {
        var placeholderParameters = (_a = placeholder === null || placeholder === void 0 ? void 0 : placeholder.customFormulaData) === null || _a === void 0 ? void 0 : _a.parameters;
        var placeholderParametersIds_1 = placeholderParameters === null || placeholderParameters === void 0 ? void 0 : placeholderParameters.map(function (parameter) { return parameter === null || parameter === void 0 ? void 0 : parameter.placeholderId; });
        var relatedPlaceholders = placeholders === null || placeholders === void 0 ? void 0 : placeholders.filter(function (placeholder) {
            return placeholderParametersIds_1 === null || placeholderParametersIds_1 === void 0 ? void 0 : placeholderParametersIds_1.includes(placeholder === null || placeholder === void 0 ? void 0 : placeholder.id);
        });
        var isMoneyFormat = relatedPlaceholders === null || relatedPlaceholders === void 0 ? void 0 : relatedPlaceholders.some(function (relatedPlaceholder) { return (relatedPlaceholder === null || relatedPlaceholder === void 0 ? void 0 : relatedPlaceholder.dataFormat) === 'money'; });
        if (isMoneyFormat) {
            if (newVal !== null && newVal !== '') {
                newVal = "".concat(newVal).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
                newVal = "".concat(newVal).replace('.', ',');
            }
        }
    }
    if (constants_1.moneyFormat.includes(placeholder.dataFormat)) {
        newVal = setMoneyValueFormat(newVal);
        if (newVal !== null && newVal !== '') {
            newVal = "".concat(newVal).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            newVal = "".concat(newVal).replace('.', ',');
        }
    }
    if (node !== null && node.innerText !== newVal) {
        node.innerText = newVal || '';
    }
}
exports.updatePlaceholderDom = updatePlaceholderDom;
// возвращает массив id плейсхолдеров, которые находятся на странице
// или null, если таких нет
function findPlaceholdersOnPage() {
    try {
        var phNodes = document.getElementsByClassName('placeholder');
        if (!phNodes || !phNodes.length)
            return null;
        var phNodesArray = Array.from(phNodes);
        var phIdsArray_1 = [];
        phNodesArray.forEach(function (ph) {
            var phId = ph.getAttribute('id') || '';
            if (phId && typeof phId === 'string') {
                phIdsArray_1.push(phId.slice(3));
            }
        });
        return phIdsArray_1.length ? phIdsArray_1 : null;
    }
    catch (error) {
        console.error(error);
        return null;
    }
}
exports.findPlaceholdersOnPage = findPlaceholdersOnPage;
function templatedListPlaceholdersValueToDom(blocks, placeholders, placeholdersValue) {
    placeholdersValue === null || placeholdersValue === void 0 ? void 0 : placeholdersValue.forEach(function (phVal) {
        var placeholderId = phVal.placeholder_id;
        var allDomPlaceholders = document.querySelectorAll("#ph_".concat(placeholderId));
        var currentPlaceholderValue = phVal === null || phVal === void 0 ? void 0 : phVal.values;
        allDomPlaceholders === null || allDomPlaceholders === void 0 ? void 0 : allDomPlaceholders.forEach(function (domPh) {
            var currentAttributeValue = domPh === null || domPh === void 0 ? void 0 : domPh.getAttribute('data-placeholder-index');
            if (currentAttributeValue) {
                var index = Number(currentAttributeValue);
                if (currentPlaceholderValue) {
                    domPh.innerHTML = currentPlaceholderValue[index];
                }
            }
        });
    });
}
exports.templatedListPlaceholdersValueToDom = templatedListPlaceholdersValueToDom;
var handleFilterOption = function (inputValue, option) {
    if (!option) {
        return false;
    }
    var template = option && option.props && option.props.children;
    if (template && typeof template === 'string') {
        return template.toLocaleUpperCase().indexOf(inputValue.toLocaleUpperCase()) >= 0;
    }
    return false;
};
exports.handleFilterOption = handleFilterOption;
var emptyNotRequiredPlaceholders = function (placeholders, placeholdersValue) {
    var nonRequiredPlaceholders = placeholders === null || placeholders === void 0 ? void 0 : placeholders.filter(function (placeholder) { return (placeholder === null || placeholder === void 0 ? void 0 : placeholder.isRequired) === false; });
    nonRequiredPlaceholders === null || nonRequiredPlaceholders === void 0 ? void 0 : nonRequiredPlaceholders.forEach(function (placeholder) {
        var placeholderId = placeholder === null || placeholder === void 0 ? void 0 : placeholder.id;
        // была ли попытка заполнить значение пх
        var findCreatedPlaceholderValue = placeholdersValue === null || placeholdersValue === void 0 ? void 0 : placeholdersValue.find(function (placeholderValue) { return (placeholderValue === null || placeholderValue === void 0 ? void 0 : placeholderValue.placeholder_id) === placeholderId; });
        var getPlaceholderNode = document.getElementById("ph_".concat(placeholderId));
        if (!findCreatedPlaceholderValue && !common_1.CheckUtils.isBoundaryLeft(getPlaceholderNode)) {
            getPlaceholderNode === null || getPlaceholderNode === void 0 ? void 0 : getPlaceholderNode.setAttribute('data-isEmpty', 'true');
            if (getPlaceholderNode) {
                getPlaceholderNode.innerHTML = '';
            }
        }
        else {
            getPlaceholderNode === null || getPlaceholderNode === void 0 ? void 0 : getPlaceholderNode.removeAttribute('data-isEmpty');
        }
    });
};
exports.emptyNotRequiredPlaceholders = emptyNotRequiredPlaceholders;
