"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLibBlockOrderMap = void 0;
var getLibBlockOrderMap = function (blocks) {
    var libBlockOrderMap = {};
    blocks.forEach(function (block) {
        if (block.isLib && block.libraryElement_id) {
            var id = block.libraryTemplate_id.id;
            var elementNumber = block.libraryElement_id.templateElementNumber;
            if ((libBlockOrderMap[id] === undefined || elementNumber < libBlockOrderMap[id])) {
                libBlockOrderMap[id] = elementNumber !== null && elementNumber !== void 0 ? elementNumber : 0;
            }
        }
    });
    return libBlockOrderMap;
};
exports.getLibBlockOrderMap = getLibBlockOrderMap;
