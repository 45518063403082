"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateElementType = void 0;
var TemplateElementType;
(function (TemplateElementType) {
    TemplateElementType[TemplateElementType["None"] = 0] = "None";
    TemplateElementType[TemplateElementType["Header1"] = 1] = "Header1";
    TemplateElementType[TemplateElementType["Header2"] = 2] = "Header2";
    TemplateElementType[TemplateElementType["PageBreak"] = 3] = "PageBreak";
    TemplateElementType[TemplateElementType["List"] = 4] = "List";
    TemplateElementType[TemplateElementType["RepeatedText"] = 5] = "RepeatedText";
    TemplateElementType[TemplateElementType["Section"] = 6] = "Section";
    TemplateElementType[TemplateElementType["Text"] = 7] = "Text";
    TemplateElementType[TemplateElementType["Table"] = 8] = "Table";
    TemplateElementType[TemplateElementType["Image"] = 9] = "Image";
    TemplateElementType[TemplateElementType["Attachment"] = 10] = "Attachment";
    TemplateElementType[TemplateElementType["Authority"] = 11] = "Authority";
})(TemplateElementType = exports.TemplateElementType || (exports.TemplateElementType = {}));
