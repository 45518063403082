"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceholdersLibraryCreatingPlaceholder = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var antd_1 = require("antd");
var react_redux_1 = require("react-redux");
var PlaceholderTemplateFolderApi_1 = require("../../../../../api/placeholderLibraryApi/PlaceholderTemplateFolder/PlaceholderTemplateFolderApi");
var react_router_dom_1 = require("react-router-dom");
var api_v1_Placeholder_1 = require("../../../../../Root/utils/api/api.v1.Placeholder");
var PlaceholderTemplateApi_1 = require("../../../../../api/placeholderLibraryApi/PlaceholderTemplate/PlaceholderTemplateApi");
var rootGetters_1 = require("../../../../../Root/redux/rootGetters/rootGetters");
var rootActionCreators_1 = require("../../../../../Root/redux/rootActionCreators/rootActionCreators");
var CustomizedForm = function (_a) {
    var onChange = _a.onChange, fields = _a.fields;
    var onKeyUp = function (value) {
        var lastChar = value.substr(value.length - 2); // => "1"
        var lastCharArray = lastChar === null || lastChar === void 0 ? void 0 : lastChar.split('');
        var first = lastCharArray[0], last = lastCharArray[1];
        var lastSymbolIntoNumber = +last;
        if (first === '(' && lastSymbolIntoNumber) {
            value = value.replace('(', '').replace(lastSymbolIntoNumber, '');
            antd_1.message.warning('Постарайтесь избежать названий ПХ с цифрами в скобках на конце');
        }
        return value;
    };
    return ((0, jsx_runtime_1.jsx)(antd_1.Form, __assign({ name: 'global_state', layout: 'inline', fields: fields, onFieldsChange: function (_, allFields) {
            // @ts-ignore
            var placeholdernameField = allFields === null || allFields === void 0 ? void 0 : allFields.find(function (field) { var _a; return (_a = field.name) === null || _a === void 0 ? void 0 : _a.includes('placeholdername'); });
            if (placeholdernameField) {
                var currentValue = placeholdernameField.value;
                var newValue = onKeyUp(currentValue);
                placeholdernameField.value = newValue;
            }
            onChange(allFields);
        } }, { children: (0, jsx_runtime_1.jsx)(antd_1.Form.Item, __assign({ name: 'placeholdername', 
            // label="Placeholdername"
            rules: [{ required: true, message: 'Введите название плейсхолдера!' }] }, { children: (0, jsx_runtime_1.jsx)(antd_1.Input, { maxLength: 250, style: { width: 400 }, autoComplete: 'off' }) })) })));
};
var PlaceholdersLibraryCreatingPlaceholder = function (_a) {
    var currentFolder = _a.currentFolder;
    var dispatch = (0, react_redux_1.useDispatch)();
    var history = (0, react_router_dom_1.useHistory)();
    var _b = (0, react_1.useState)(false), confirmLoading = _b[0], setConfirmLoading = _b[1];
    var historyLocationState = history.location.state || {};
    // @ts-ignore
    var isHiddenPh = historyLocationState === null || historyLocationState === void 0 ? void 0 : historyLocationState.isHiddenPh;
    var _c = (0, react_1.useState)([{ name: ['placeholdername'], value: '' }]), fields = _c[0], setFields = _c[1];
    var presentState = (0, react_redux_1.useSelector)(rootGetters_1.getPresentState);
    var isPlaceholderModalOpen = presentState === null || presentState === void 0 ? void 0 : presentState.creatingPlaceholderModal;
    var openedFolderContent = presentState === null || presentState === void 0 ? void 0 : presentState.placeholderTemplateFolderContent;
    var contentTemplatesList = openedFolderContent === null || openedFolderContent === void 0 ? void 0 : openedFolderContent.templates;
    // закрываем попап создания пх
    var onCancel = (0, react_1.useCallback)(function () {
        dispatch((0, rootActionCreators_1.setCreatingPlaceholderModal)(false));
    }, []);
    var addPlaceholder = (0, react_1.useCallback)(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setConfirmLoading(true);
            setTimeout(function () { return __awaiter(void 0, void 0, void 0, function () {
                var currentPlaceholderName, nameForPh, currentFolderId, placeholderId_1, templateId, _placeholders, currentPlaceholder, data, findEqualName, findWithEqualName, findAllSimilarNames, lastPartsOfNames, lastPartsOfNamesRemoveEmpty, aliasWithOrders_1, currentNames, maxNumber, nextOrder, saveData, createdPhTemplate, record;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            currentPlaceholderName = (_a = fields[0]) === null || _a === void 0 ? void 0 : _a.value;
                            nameForPh = currentPlaceholderName;
                            currentFolderId = currentFolder === null || currentFolder === void 0 ? void 0 : currentFolder.id;
                            if (!((currentPlaceholderName === null || currentPlaceholderName === void 0 ? void 0 : currentPlaceholderName.length) > 0 && (currentPlaceholderName === null || currentPlaceholderName === void 0 ? void 0 : currentPlaceholderName.length) <= 250)) return [3 /*break*/, 5];
                            placeholderId_1 = historyLocationState === null || historyLocationState === void 0 ? void 0 : historyLocationState.placeholderId;
                            templateId = historyLocationState === null || historyLocationState === void 0 ? void 0 : historyLocationState.templateId;
                            return [4 /*yield*/, (0, api_v1_Placeholder_1.v1PlaceholderByTemplate)({ templateId: templateId })];
                        case 1:
                            _placeholders = _b.sent();
                            currentPlaceholder = _placeholders === null || _placeholders === void 0 ? void 0 : _placeholders.find(function (_placeholder) { return _placeholder.id === placeholderId_1; });
                            data = currentPlaceholder || {};
                            findEqualName = contentTemplatesList === null || contentTemplatesList === void 0 ? void 0 : contentTemplatesList.some(function (template) { return template.name === currentPlaceholderName; });
                            if (findEqualName) {
                                findWithEqualName = contentTemplatesList === null || contentTemplatesList === void 0 ? void 0 : contentTemplatesList.find(function (template) { return template.name === currentPlaceholderName; });
                                if (findWithEqualName) {
                                    findAllSimilarNames = contentTemplatesList === null || contentTemplatesList === void 0 ? void 0 : contentTemplatesList.filter(function (template) { return template.name.includes(currentPlaceholderName); });
                                    lastPartsOfNames = findAllSimilarNames === null || findAllSimilarNames === void 0 ? void 0 : findAllSimilarNames.map(function (template) { return template === null || template === void 0 ? void 0 : template.name.replace(currentPlaceholderName, ''); });
                                    lastPartsOfNamesRemoveEmpty = lastPartsOfNames === null || lastPartsOfNames === void 0 ? void 0 : lastPartsOfNames.filter(function (name) { return name !== ''; });
                                    aliasWithOrders_1 = [];
                                    if (lastPartsOfNamesRemoveEmpty === null || lastPartsOfNamesRemoveEmpty === void 0 ? void 0 : lastPartsOfNamesRemoveEmpty.length) {
                                        currentNames = lastPartsOfNamesRemoveEmpty === null || lastPartsOfNamesRemoveEmpty === void 0 ? void 0 : lastPartsOfNamesRemoveEmpty.filter(function (name) {
                                            var start = name === null || name === void 0 ? void 0 : name.startsWith('(');
                                            var end = name === null || name === void 0 ? void 0 : name.endsWith(')');
                                            if (start && end) {
                                                var contentOfBrackets = name === null || name === void 0 ? void 0 : name.replace('(', '').replace(')', '');
                                                var intoNumber = +contentOfBrackets;
                                                if (intoNumber && (typeof intoNumber === 'number')) {
                                                    aliasWithOrders_1 === null || aliasWithOrders_1 === void 0 ? void 0 : aliasWithOrders_1.push(intoNumber);
                                                }
                                            }
                                        });
                                    }
                                    if (aliasWithOrders_1 === null || aliasWithOrders_1 === void 0 ? void 0 : aliasWithOrders_1.length) {
                                        maxNumber = Math.max.apply(Math, aliasWithOrders_1);
                                        nextOrder = maxNumber + 1;
                                        nameForPh = "".concat(currentPlaceholderName, "(").concat(nextOrder, ")");
                                    }
                                    else {
                                        nameForPh = "".concat(currentPlaceholderName, "(").concat(1, ")");
                                    }
                                }
                            }
                            if (!data) return [3 /*break*/, 4];
                            saveData = __assign(__assign({}, data), { initial_title: nameForPh, name: nameForPh, title: nameForPh, isHidden: (isHiddenPh === null || isHiddenPh === void 0 ? void 0 : isHiddenPh.opt) || false });
                            return [4 /*yield*/, (0, PlaceholderTemplateApi_1.placeholderTemplateCreate)({ data: saveData, folderId: currentFolderId, name: nameForPh })];
                        case 2:
                            createdPhTemplate = _b.sent();
                            dispatch((0, rootActionCreators_1.setPhLibraryCreatedItem)(createdPhTemplate));
                            return [4 /*yield*/, (0, PlaceholderTemplateFolderApi_1.placeholderTemplateFolderContent)({ id: currentFolderId }).then(function (response) { return dispatch((0, rootActionCreators_1.setPlaceholderTemplateFolderContent)(response)); })];
                        case 3:
                            _b.sent();
                            antd_1.message.success("\u041F\u0425 \u0441 \u0438\u043C\u0435\u043D\u0435\u043C [".concat(nameForPh, "] \u0443\u0441\u043F\u0435\u0448\u043D\u043E \u0441\u043E\u0437\u0434\u0430\u043D\u043E"));
                            record = { key: createdPhTemplate === null || createdPhTemplate === void 0 ? void 0 : createdPhTemplate.id, name: nameForPh, template: true };
                            dispatch((0, rootActionCreators_1.setActivePlaceholderRecord)(record));
                            dispatch((0, rootActionCreators_1.setOpenPlaceholderDrawer)(true));
                            setFields([{ name: ['placeholdername'], value: '' }]);
                            _b.label = 4;
                        case 4: return [3 /*break*/, 6];
                        case 5:
                            antd_1.message.warning('Чтобы ПХ создалось, необходимо указать его название!');
                            _b.label = 6;
                        case 6:
                            dispatch((0, rootActionCreators_1.setCreatingPlaceholderModal)(false));
                            setConfirmLoading(false);
                            return [2 /*return*/];
                    }
                });
            }); }, 1000);
            return [2 /*return*/];
        });
    }); }, [fields, currentFolder, contentTemplatesList]);
    return ((0, jsx_runtime_1.jsx)(antd_1.Modal, __assign({ open: isPlaceholderModalOpen, title: 'Создание ПХ', onCancel: onCancel, onOk: addPlaceholder, confirmLoading: confirmLoading, width: '600px', centered: true }, { children: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(CustomizedForm, { fields: fields, onChange: function (newFields) {
                    setFields(newFields);
                } }) }) })));
};
exports.PlaceholdersLibraryCreatingPlaceholder = PlaceholdersLibraryCreatingPlaceholder;
