// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImagePresenter-module__urlContainer___RClss {\n  display: flex;\n  margin: 10px 0;\n}\n\n.ImagePresenter-module__confirmBtn___eyjvc {\n  margin-left: 10px;\n}\n\n.ImagePresenter-module__confirmBtn___eyjvc span {\n  min-width: 40px !important;\n}\n", "",{"version":3,"sources":["webpack://./src/shared/components/ImagePresenter/ImagePresenter.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".urlContainer {\n  display: flex;\n  margin: 10px 0;\n}\n\n.confirmBtn {\n  margin-left: 10px;\n}\n\n.confirmBtn span {\n  min-width: 40px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"urlContainer": "ImagePresenter-module__urlContainer___RClss",
	"confirmBtn": "ImagePresenter-module__confirmBtn___eyjvc"
};
export default ___CSS_LOADER_EXPORT___;
