"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getUnfilledSections = exports.filterAllRequiredSections = exports.filterRequiredNestedSections = exports.filterRequiredSections = void 0;
var lodash_1 = require("lodash");
var filterRequiredSections = function (blocks) {
    var requiredSections = blocks.filter(function (block) { return block.type === 'section'
        && block.isRequired
        && block.content
        && !block.external_params
        && !block.content.parent_id; });
    return requiredSections;
};
exports.filterRequiredSections = filterRequiredSections;
var filterRequiredNestedSections = function (sections) {
    var requiredSections = sections.filter(function (section) { return section.type === 'section'
        && section.sectionId
        && section.isRequired
        && section.content
        && !section.external_params
        && !section.content.parent_id; });
    return requiredSections;
};
exports.filterRequiredNestedSections = filterRequiredNestedSections;
/**
 * Получаем все секции для проверки в том числе и те которые управляются другими элементами
 * @param sections Массив секций
 * @param blocks Массив блоков
 * @returns
 */
var filterAllRequiredSections = function (sections, blocks) {
    var allData = __spreadArray(__spreadArray([], sections, true), blocks, true);
    var allRequiredSections = allData.filter(function (section) { return section.type === 'section'
        && section.isRequired
        && section.content; });
    return allRequiredSections;
};
exports.filterAllRequiredSections = filterAllRequiredSections;
var getUnfilledSections = function (documentId, blocks, sections, presenter) {
    if (blocks === void 0) { blocks = []; }
    if (sections === void 0) { sections = []; }
    // все секции для проверки родительских отношений
    var allRequiredCheckSections = (0, exports.filterAllRequiredSections)(sections, blocks);
    // выявляем обязательные для заполнения секции
    var reqSections = (0, exports.filterRequiredSections)(blocks);
    // выявляем обязательные для заполнения вложенные секции
    var reqNestedSections = (0, exports.filterRequiredNestedSections)(sections);
    // для проверки нужны и вложенные и корневые секции
    var allReqSections = __spreadArray(__spreadArray([], reqSections, true), reqNestedSections, true);
    // выявляем обязательные для заполнения варианты секций
    var sectionOptions = (0, exports.filterRequiredSections)(sections);
    // формируем массив с обязательными секциями и вариантами секций
    var allSections = __spreadArray(__spreadArray([], reqSections, true), sectionOptions, true);
    var allOptions = allSections.flatMap(function (sect) { return (sect.groupId ? [sect.groupId] : []); });
    function checkSectionFilling(section) {
        var _a, _b;
        // activeOptions содержит в себе информацию о заполненности блока
        // в виде пары id блока - id выбранного варианта
        var selectedOption = (0, lodash_1.get)(section, "content.activeOptions.".concat(documentId), false);
        // если выбранных опций нет то эта секция не заполнена
        var unFilledSection = (!selectedOption || !selectedOption.length);
        // если это внутренняя секция
        if (section.sectionId) {
            // если не заполнена
            if (unFilledSection) {
                // смотрим выбрана ли она в родительской
                var parentSection_1 = allRequiredCheckSections.find(function (x) { return x.id === section.sectionId; });
                // Если в свою очередь и родительская секция вложенная
                // Проверяем только выбранность ее родительской!!! (задача DC-4552)
                if (parentSection_1 === null || parentSection_1 === void 0 ? void 0 : parentSection_1.sectionId) {
                    var parentSection2 = allRequiredCheckSections.find(function (x) { return x.id === parentSection_1.sectionId; });
                    // выбранные опции родительской секции
                    var selectedOptionParent2 = (0, lodash_1.get)(parentSection2, "content.activeOptions.".concat(documentId), false) || [];
                    // если в родительской ничего не выбрано то и текущую не надо рассматривать - предупреждение будет на "верхнем" уровне
                    var unFilledSectionParent2 = (!selectedOptionParent2 || !selectedOptionParent2.length);
                    if (unFilledSectionParent2)
                        return false;
                }
                // Родительская секция управляется другой секцией
                if ((_a = parentSection_1 === null || parentSection_1 === void 0 ? void 0 : parentSection_1.content) === null || _a === void 0 ? void 0 : _a.parent_id) {
                    // Находим управляющую секцию
                    var ownerSection = allRequiredCheckSections.find(function (x) { var _a; return x.id === ((_a = parentSection_1 === null || parentSection_1 === void 0 ? void 0 : parentSection_1.content) === null || _a === void 0 ? void 0 : _a.parent_id); });
                    if (!ownerSection)
                        return false;
                    // выбранные опции управляющей секции
                    var selectedOptionOwner = (0, lodash_1.get)(ownerSection, "content.activeOptions.".concat(documentId), false) || [];
                    // если в управляющей ничего не выбрано то и текущую не надо рассматривать - предупреждение будет на "верхнем" уровне
                    var unFilledSectionOwner = (!selectedOptionOwner || !selectedOptionOwner.length);
                    if (unFilledSectionOwner)
                        return false;
                    // Нужно в линках найти соответствующую связь с parentSection
                    var linkToParent = (_b = ownerSection === null || ownerSection === void 0 ? void 0 : ownerSection.content) === null || _b === void 0 ? void 0 : _b.links[parentSection_1.id];
                    // Выбранная опция в управляющей секции есть ключ
                    var data = linkToParent[selectedOptionOwner[0]];
                    // данная секция будет считаться не заполненной только в том случае
                    // если она совпадает с выбранным вариантом у родительской
                    var checked = (data[0].groupId === section.groupId);
                    return checked;
                }
                else {
                    // выбранные опции родительской секции
                    var selectedOptionParent = (0, lodash_1.get)(parentSection_1, "content.activeOptions.".concat(documentId), false) || [];
                    // если в родительской ничего не выбрано то и текущую не надо рассматривать - предупреждение будет на "верхнем" уровне
                    var unFilledSectionParent = (!selectedOptionParent || !selectedOptionParent.length);
                    if (unFilledSectionParent)
                        return false;
                    // смотрим зависимость плейсходеров, если они зависят, то при любом выборе родительской секции
                    // это секция будет незаполненная и должно быть предупреждение
                    if (presenter) {
                        var placeholders = presenter.getUnfilledPlaceholders([section]);
                        // Только те ПХ которые имееют отношения
                        var placeholdersParents = placeholders.filter(function (x) { return x.parent_id; });
                        var status_1 = placeholdersParents && placeholdersParents.length > 0;
                        if (status_1)
                            return true;
                    }
                    // данная секция будет считаться не заполненной только в том случае
                    // если она совпадает с выбранным вариантом у родительской
                    var checked = selectedOptionParent.find(function (x) { return x === section.groupId; });
                    return checked;
                }
                // eslint-disable-next-line no-else-return
            }
            else {
                return false;
            }
            // eslint-disable-next-line no-else-return
        }
        else {
            // если выбранных опций нет то эта секция не заполнена
            if (unFilledSection) {
                return true;
            }
            // перебираем массив с информацией о заполненности блоков
            return selectedOption.some(function (current) {
                // если блок не заполнен
                if (!allOptions.includes(current) && selectedOption.length === 1) {
                    return false;
                }
                // у текущего варианта секции забираем id дочернего блока
                var nextSect = sectionOptions.find(function (opt) { return opt.groupId === current; });
                // если он есть, еще раз запускаем эту функцию
                if (nextSect) {
                    return checkSectionFilling(nextSect);
                }
                return false;
            });
        }
    }
    return allReqSections.filter(function (x) { return checkSectionFilling(x); });
};
exports.getUnfilledSections = getUnfilledSections;
