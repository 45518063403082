"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getImageSize = exports.getImageSizeByName = void 0;
function getImageSizeByName(size) {
    switch (size) {
        case 'medium':
            return [12, size];
        case 'large':
            return [16, size];
        default: // "small"
            return [8, size];
    }
}
exports.getImageSizeByName = getImageSizeByName;
function getImageSize(list) {
    var size = list && list[0] && list[0].size ? list[0].size : 'small';
    return getImageSizeByName(size);
}
exports.getImageSize = getImageSize;
