"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TypeUtils = void 0;
var constants_1 = require("../../constants");
var CheckUtils_1 = require("./CheckUtils");
var TypeUtils = /** @class */ (function () {
    function TypeUtils() {
    }
    TypeUtils.isElement = function (node) {
        return !!node && typeof node === 'object' && 'getAttribute' in node;
    };
    TypeUtils.isHtmlElement = function (node) {
        return TypeUtils.isElement(node) && 'style' in node;
    };
    TypeUtils.isNode = function (node) {
        return !!node && typeof node === 'object' && 'parentNode' in node;
    };
    TypeUtils.isTextNode = function (node) {
        return TypeUtils.isNode(node) && node.nodeType === Node.TEXT_NODE;
    };
    // блок с параграфами
    TypeUtils.isWrappableBlock = function (node) { return CheckUtils_1.CheckUtils.getWrappableAttr(node) === 'block'; };
    TypeUtils.isWrappableList = function (node) { return CheckUtils_1.CheckUtils.getWrappableAttr(node) === 'list'; };
    TypeUtils.isWrappableTableCell = function (node) {
        return CheckUtils_1.CheckUtils.getWrappableAttr(node) === 'table-cell';
    };
    TypeUtils.isBlockText = function (node) { return CheckUtils_1.CheckUtils.hasClassName(node, constants_1.CLASS_NAMES.textBlock); };
    TypeUtils.isBlockList = function (node) { return CheckUtils_1.CheckUtils.hasClassName(node, constants_1.CLASS_NAMES.listBlock); };
    TypeUtils.isBlockTableCell = function (node) {
        return CheckUtils_1.CheckUtils.hasClassName(node, constants_1.CLASS_NAMES.tableCellBlock);
    };
    // текст и текст ПП
    TypeUtils.isInlineText = function (node) {
        return CheckUtils_1.CheckUtils.hasClassName(node, constants_1.CLASS_NAMES.inlineText);
    };
    // только текст, без ПП
    TypeUtils.isInlineTextWithoutBoundary = function (node) {
        return CheckUtils_1.CheckUtils.isInlineText(node) && !CheckUtils_1.CheckUtils.isBoundary(node);
    };
    TypeUtils.isListElement = function (node) {
        return TypeUtils.isElement(node) && (node.tagName === 'OL' || node.tagName === 'UL');
    };
    TypeUtils.isParagraphNode = function (node) { return CheckUtils_1.CheckUtils.isParagraphNode(node); };
    return TypeUtils;
}());
exports.TypeUtils = TypeUtils;
