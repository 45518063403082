"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NODE_STYLE = exports.TEXT_STYLES = exports.ZWNJ = exports.U_ZWNJ = void 0;
__exportStar(require("./general"), exports);
__exportStar(require("./boundary"), exports);
__exportStar(require("./general"), exports);
exports.U_ZWNJ = '\u200c';
exports.ZWNJ = '&zwnj;';
exports.TEXT_STYLES = {
    JustifyLeft: {
        cn: 'justify-left',
        style: 'text-align:left',
        ulStyle: '-left',
        position: 'position-left'
    },
    JustifyCenter: {
        cn: 'justify-center',
        style: 'text-align:center',
        ulStyle: '-center',
        position: 'position-center'
    },
    JustifyRight: {
        cn: 'justify-right',
        style: 'text-align:right',
        ulStyle: '-right',
        position: 'position-right'
    },
    Justify: {
        cn: 'justify',
        style: '',
        ulStyle: '',
        position: 'position'
    },
};
exports.NODE_STYLE = {
    bold: {
        tagName: 'B',
        class: 'bold',
    },
    italic: {
        tagName: 'I',
        class: 'italic',
    },
    underline: {
        tagName: 'U',
        class: 'underline',
    },
};
