"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaceholderLibraryHiddenInfo = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var antd_1 = require("antd");
require("../../UserHiddenInfo/UserHiddenInfo.less");
var PlaceholderLibraryHiddenInfo = function (_a) {
    var disabled = _a.disabled, useReducerDispatch = _a.useReducerDispatch, aggregation = _a.aggregation;
    var setPlaceholderState = function (value) {
        useReducerDispatch({
            type: 'set_isHidden',
            isHidden: value,
        });
    };
    var defaultCheckOpt = aggregation === null || aggregation === void 0 ? void 0 : aggregation.isHidden;
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: 'option' }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: 'content' }, { children: [(0, jsx_runtime_1.jsx)(antd_1.Switch, { size: 'small', id: 'hide_placeholder', checked: defaultCheckOpt, onChange: setPlaceholderState, disabled: disabled }), (0, jsx_runtime_1.jsx)("label", __assign({ htmlFor: 'hide_placeholder', className: 'label' }, { children: "\u0421\u043A\u0440\u044B\u0442\u044C \u0432 \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u0435" }))] })) })));
};
exports.PlaceholderLibraryHiddenInfo = PlaceholderLibraryHiddenInfo;
