"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.linkTemplatedListHeadAndBlock = exports.loadTemplatedListSections = exports.templatedListEditableOff = exports.loadTemplatedListContent = exports.clearLibArtefactsForPlaceholder = exports.clearLibArtefactsForBlock = exports.changeContentIds = void 0;
var templatedListConstants_1 = require("../../shared/utils/templatedListConstants");
var changeContentIds = function (content, libraryIds) {
    var strContent = JSON.stringify(content);
    Object.keys(libraryIds).forEach(function (id) {
        strContent = strContent.replaceAll(id, libraryIds[id]);
    });
    return JSON.parse(strContent);
};
exports.changeContentIds = changeContentIds;
var clearLibArtefactsForBlock = function (libraryIds) { return function (block) {
    if (block.id in libraryIds) {
        var isSectionBlock = block.sectionId in libraryIds;
        var sectionId = isSectionBlock ? libraryIds[block.sectionId] : block.sectionId;
        // При присваивании порядкового номера также должны учитывать возможность нахождения блока в секции
        // и если он в секции то префикс номера обязательно id секции
        var order = isSectionBlock ? "".concat(sectionId).concat(block.order.substr(36)) : block.order;
        return __assign(__assign({}, block), { id: libraryIds[block.id], sectionId: sectionId, order: order, fakeElement_id: block.libraryTemplate_id, libraryTemplate_id: undefined, libraryElement_id: undefined, isLib: false, isContentEditable: true, content: (0, exports.changeContentIds)(block.content, libraryIds) });
    }
    return block;
}; };
exports.clearLibArtefactsForBlock = clearLibArtefactsForBlock;
var clearLibArtefactsForPlaceholder = function (libraryIds) { return function (placeholder) {
    if (placeholder.elementId in libraryIds) {
        return __assign(__assign({}, placeholder), { id: libraryIds[placeholder.id], elementId: libraryIds[placeholder.elementId], parent_id: placeholder.parent_id in libraryIds ? libraryIds[placeholder.parent_id] : placeholder.parent_id, isLib: false });
    }
    if (placeholder.parent_id in libraryIds) {
        return __assign(__assign({}, placeholder), { parent_id: libraryIds[placeholder.parent_id] });
    }
    return placeholder;
}; };
exports.clearLibArtefactsForPlaceholder = clearLibArtefactsForPlaceholder;
var loadTemplatedListContent = function (blocks, templatedListPlaceholders, templatedListBlocks, isDocument) {
    var blocksWithTl = blocks === null || blocks === void 0 ? void 0 : blocks.filter(function (block) { return block.subType === templatedListConstants_1.options.templatedList.block; });
    blocksWithTl === null || blocksWithTl === void 0 ? void 0 : blocksWithTl.forEach(function (block) {
        if (block.subType === templatedListConstants_1.options.templatedList.block) {
            var currentBlockIndex = blocks.indexOf(block);
            var currentBlockId_1 = block === null || block === void 0 ? void 0 : block.id;
            var findCurrentPlaceholder = templatedListPlaceholders === null || templatedListPlaceholders === void 0 ? void 0 : templatedListPlaceholders.find(function (placeholder) { return placeholder.elementId === currentBlockId_1; });
            var findCurrentPlaceholderId_1 = findCurrentPlaceholder === null || findCurrentPlaceholder === void 0 ? void 0 : findCurrentPlaceholder.id;
            var searchTemplatedBlockItem = templatedListBlocks === null || templatedListBlocks === void 0 ? void 0 : templatedListBlocks.filter(function (block) { return block.placeholderId === findCurrentPlaceholderId_1; });
            if (searchTemplatedBlockItem.length) {
                var templateElements = searchTemplatedBlockItem === null || searchTemplatedBlockItem === void 0 ? void 0 : searchTemplatedBlockItem.map(function (item) { return item.templateElement; });
                var check = templateElements === null || templateElements === void 0 ? void 0 : templateElements.every(function (tlElem) { return blocks.includes(tlElem); });
                if (!check) {
                    templateElements.forEach(function (element, index) {
                        if (isDocument && element.type !== 'section') {
                            element.id = "".concat(element.id, "_").concat(index);
                        }
                        element.autonumeric = false;
                    });
                    var addTlBlock = blocks.splice.apply(blocks, __spreadArray([currentBlockIndex + 1, 0], templateElements, false));
                }
            }
        }
    });
    blocks === null || blocks === void 0 ? void 0 : blocks.forEach(function (block, index) {
        block.order = index;
        if (block.subType === templatedListConstants_1.options.templatedList.addedTlBlock) {
            block.subType = templatedListConstants_1.options.templatedList.TlBlock;
        }
    });
    return blocks;
};
exports.loadTemplatedListContent = loadTemplatedListContent;
var templatedListEditableOff = function (blocks) {
    blocks === null || blocks === void 0 ? void 0 : blocks.forEach(function (block) {
        if (block.subType === templatedListConstants_1.options.templatedList.TlBlock) {
            block.isContentEditable = false;
        }
    });
    return blocks;
};
exports.templatedListEditableOff = templatedListEditableOff;
//выставляем нумерацию для повторяющихся секций от сформированных списков
var loadTemplatedListSections = function (extractsRepeatedSections, allTlSections) {
    var sectionsFilter = extractsRepeatedSections.filter(function (section) { return section.templateElement.elementType === 'section'; });
    var newSectionBlocks = [];
    sectionsFilter.forEach(function (section) {
        var sectionId = section === null || section === void 0 ? void 0 : section.templateElement.id;
        var currentSectionBlocks = allTlSections.filter(function (element) { return element.sectionId === sectionId; });
        // группировка по схожим id
        var map = currentSectionBlocks.reduce(function (r, i) {
            r[i.id] = r[i.id] || [];
            r[i.id].push(i);
            return r;
        }, {});
        var arr1 = [];
        for (var key in map) {
            arr1.push(map[key]);
        }
        var newArray = [];
        // выставляем индексы айдишникам
        arr1.forEach(function (alias) {
            var currentArr = alias.map(function (elem, index) {
                var currentElem = __assign(__assign({}, elem), { id: "".concat(elem.id, "_").concat(index) });
                return currentElem;
            });
            newArray.push.apply(newArray, currentArr);
        });
        newSectionBlocks.push.apply(newSectionBlocks, newArray);
    });
    return newSectionBlocks;
};
exports.loadTemplatedListSections = loadTemplatedListSections;
function linkTemplatedListHeadAndBlock(templatedListBlocks, placeholders, blocks) {
    // Соединяем шапки и с блоками
    templatedListBlocks.forEach(function (x) {
        var phId = x.placeholderId;
        var ph = placeholders === null || placeholders === void 0 ? void 0 : placeholders.find(function (x) { return x.id === phId; });
        if (ph && ph.elementId) {
            // Находим головной элемент
            var head = blocks.find(function (b) { return b.id === ph.elementId; });
            if (head) {
                head.templateListId = x.templateElement.template_id.id;
            }
        }
    });
}
exports.linkTemplatedListHeadAndBlock = linkTemplatedListHeadAndBlock;
