"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnfilledPlaceHolder = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var templatedListConstants_1 = require("../../../shared/utils/templatedListConstants");
var UnfilledPlaceHolderContent_1 = require("./UnfilledInfoContent/UnfilledPlaceHolderContent");
var UnfilledTemplatedListPlaceHolderContent_1 = require("./UnfilledInfoContent/UnfilledTemplatedListPlaceHolderContent");
var UnfilledPlaceHolder = function (_a) {
    var placeholder = _a.placeholder, ind = _a.ind, filteredPlaceholdersValue = _a.filteredPlaceholdersValue, _b = _a.templatedListBlocks, templatedListBlocks = _b === void 0 ? [] : _b, _c = _a.sections, sections = _c === void 0 ? [] : _c;
    var title = placeholder.title;
    // условие, что ПХ является Списковым
    var isTemplatedListPlaceholder = (placeholder === null || placeholder === void 0 ? void 0 : placeholder.subType) === templatedListConstants_1.options.templatedList.placeholder;
    var currentPlaceholderOrder = typeof ind === 'number' ? "".concat(ind + 1, ".") : '';
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: isTemplatedListPlaceholder
            ? (0, jsx_runtime_1.jsx)(UnfilledTemplatedListPlaceHolderContent_1.UnfilledTemplatedListPlaceHolderContent, { currentPlaceholderOrder: currentPlaceholderOrder, placeholder: placeholder, filteredPlaceholdersValue: filteredPlaceholdersValue, templatedListBlocks: templatedListBlocks, sections: sections })
            : (0, jsx_runtime_1.jsx)(UnfilledPlaceHolderContent_1.UnfilledPlaceHolderContent, { currentPlaceholderOrder: currentPlaceholderOrder, title: title || '' }) }));
};
exports.UnfilledPlaceHolder = UnfilledPlaceHolder;
